import React from 'react';
import { TbX } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { SITELINK } from '../../../app-routes/Links';
import { UserInterface } from '../../../app-schemas/profile.schema';

interface WelcomeMessageProps {
  user: UserInterface;
  onClose: () => void;
}

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ user, onClose }) => {
  return (
    <section className="p-5 mt-1">
      <div className="flex flex-col bg-blue-200 box-border border border-blue-200 shadow-sm p-5 rounded-xl">
        <span className="text-lg text-blue-700 gap-4 flex justify-end z-10">
          <button onClick={onClose}>
            <TbX />
          </button>
        </span>

        <div className="top-[-15px] relative">
          <p className="font-medium text-lg">Hello {user?.username ?? 'User'},</p>
          <p className="text-xs text-gray-800 font-medium mt-2">
            You have been automatically added to a community in "{user?.residency_city ?? ' '}"
            based on your profile update.{'    '}
            <Link
              className="text-custom-600 hover:underline font-medium"
              to={SITELINK.COMMUNITIES}
            >
              View
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default WelcomeMessage;
