import React, { useState } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { LuAsterisk } from 'react-icons/lu';

type InputFieldProps = {
  id: string;
  className?: string;
  placeholder?: string;
  label?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  icon?: React.ComponentType;
  max?: number;
  min?: number;
  step?: number;
  error?: string;
  maxLength?: number;
  disabled?: boolean;
};

const TextArea: React.FC<InputFieldProps> = ({
  id,
  className = '',
  placeholder,
  label,
  value,
  onChange,
  required = false,
  icon: Icon,
  max,
  min,
  step,
  error,
  maxLength,
  disabled,
}) => {
  const [isFocused, setIsFocused] = useState(true);

  

  return (
    <div className={`relative ${className}`}>
      
        <>
          {label && isFocused && (
            <label
              htmlFor={id}
              className="flex text-xs text-gray-700 font-[600] pl-[15px]"
            >
              {required && (
                <span className="text-red-600 top-[2px] relative">
                  <LuAsterisk />
                </span>
              )}
              {label}
            </label>
          )}

          <div className='relative'>
            {Icon && (
              <span className="absolute p-[5px] rounded-sm top-[8px] left-[7px] text-xl text-[#98A1B0]">
                <Icon />
              </span>
            )}

            <textarea
              id={id}
              className={`focus:outline-none focus:shadow-outline border border-input border-gray-400 rounded-[3px] pl-10 px-4 py-3 w-full text-md text-gray-700 focus:shadow-lg focus:border-[#58595c] ${
                error && 'border-red-700'
              } ${className}`}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onBlur={onChange}
              onFocus={() => setIsFocused(true)}
              // onBlur={() => setIsFocused(true)}
              // required={required}
              autoComplete="off"
              maxLength={maxLength}
              disabled={disabled}
            ></textarea>

            {error && <ErrorMessage message={error} />}
          </div>
        </>
      
    </div>
  );
};

export default TextArea;
