import axios, { AxiosResponse } from 'axios';
import { getToken } from '../app-utils';
import { SERVER_URL } from './process';

// axios.defaults.withCredentials = true;
axios.defaults.baseURL = SERVER_URL;

export const makeRequestWithoutToken = {
  get: (endpoint: string): Promise<AxiosResponse> => axios.get(endpoint),
  post: (endpoint: string, data: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.post(endpoint, data),
  put: (endpoint: string, data: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.put(endpoint, data),
  delete: (endpoint: string, data: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.delete(endpoint, { data }),
  patch: (endpoint: string, data: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.patch(endpoint, data),
};

export const makeRequestWithToken = {
  get: (endpoint: string, params: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params,
    }),
  post: (endpoint: string, data: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.post(endpoint, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  put: (endpoint: string, data: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.put(endpoint, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
  delete: (endpoint: string, data: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data,
    }),
  patch: (endpoint: string, data: Record<string, any> = {}): Promise<AxiosResponse> =>
    axios.patch(endpoint, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }),
};
