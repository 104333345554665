import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LuMoreHorizontal } from 'react-icons/lu';
import { SITELINK } from '../../../app-routes/Links';
import { generateRandomHexColor, getFirstLetters, getUser, truncateText } from '../../../app-utils';
import { Verify } from '../../../assets/custom-icons';
import LazyLoad from 'react-lazyload';
import { useLikeFeeds } from '../../../api-services/feeds/useLikeFeeds';
import { PiShareFatDuotone } from 'react-icons/pi';
import { TbMessageCircle } from 'react-icons/tb';
import Modal from '../../../components/modal';
import { useSaveFeeds } from '../../../api-services/feeds/useSaveFeeds';
import { UserInterface } from '../../../app-schemas/profile.schema';
import { useDeleteFeeds } from '../../../api-services/feeds/useDeleteFeeds';

// ** Define the types for the post and its properties
interface Post {
  audio?: string;
  bookmarked_post: boolean;
  comments: number;
  community: string;
  content: string;
  created_on: string;
  creator: {
    email: string;
    first_name: string;
    is_verified: boolean;
    last_name: string;
    username: string;
    profile_picture_url: string;
  };
  images: string[];
  is_boosted: boolean;
  post_id: number;
  reacted_to_post: boolean;
  reactions: number;
}


// ** Define the props type for the component
interface PostCardProps {
  post: Post;
  index: number;
}

const PostCard: React.FC<PostCardProps> = ({ post, index }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  console.log(currentPath);
  

  const likeFeed = useLikeFeeds();
  const saveFeed = useSaveFeeds();
  const deleteFeed = useDeleteFeeds();
  const user: UserInterface = getUser();

  const [openSetup, setOpenSetup] = useState<boolean>(false);
  // const [feedInfo, setFeedID] = useState<Partial<Post | undefined>>({});

  const handleOpenSetup = () => {
    openSetup
      ? document.body.classList.remove('overflow-hidden')
      : document.body.classList.add('overflow-hidden');
    setOpenSetup(!openSetup);
  };

  const handleLike = (id: number) => {
    likeFeed.mutate(id);
  };

  const handleSave = (id: number) => {
    saveFeed.mutate(id);
    handleOpenSetup();
  };

  const handleDelete = (id: number) => {
    deleteFeed.mutate(id);
    handleOpenSetup();
  };

  const handleShare = (id: number) => {
    // Check if the Web Share API is supported by the browser
    if (navigator.share) {
      // The data to be shared
      const shareData = {
        title: 'Catapot',
        text: 'my post from catapot.com',
        url: 'catapot.com',
      };

      // Trigger the share dialog
      navigator
        .share(shareData)
        .then(() => {
          console.log('Share successful');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
    } else {
      console.log('Web Share API is not supported in this browser.');
      // Provide a fallback or alternative sharing option for unsupported platforms
      // For example, you could open a modal with a share link or use a custom sharing solution
    }
  };

  return (
    <div key={index} className="bg-white mt-2 border border-gray-300 shadow-sm">
      {/* name & community caption area &&  isBoosted*/}

      <div className="flex gap-2 justify-between p-3 pb-0">
        {/* name & community caption area */}
        <div className="flex gap-2">
          <div>
            <Link to={`${SITELINK.MY_PROFILE}/${post?.creator?.username}`}>
              {/* profile pix area */}
              {post?.creator?.profile_picture_url ? (
                <div className="flex-grow flex flex-col items-center">
                  <div className="overflow-hidden flex justify-center">
                    <LazyLoad height={200} offset={1000} once key={index}>
                      <img
                        alt="profile"
                        src={post?.creator?.profile_picture_url}
                        className="object-cover rounded-full w-[27px] h-[27px] bg-gray-200 shadow-sm overflow-hidden"
                      />
                    </LazyLoad>
                  </div>
                </div>
              ) : (
                <span
                  className={`block w-[27px] h-[27px] rounded-full border border-green-100`}
                  style={{ background: generateRandomHexColor() }}
                >
                  <h3 className="text-white uppercase text-[1rem] font-[500] relative top-[1px] left-1.5">
                    {getFirstLetters(post?.creator?.username)}
                  </h3>
                </span>
              )}
            </Link>
          </div>

          <div>
            <p className="text-xs text-gray-800 font-semibold flex gap-1">
              <span>{post?.creator?.username ? post?.creator?.username : 'Anonymous'}</span>
              <span>
                {post?.creator?.is_verified && <img src={Verify} alt="" className="h-[12px]" />}
              </span>
            </p>

            <div className="flex gap-2 relative">
              <p className="text-[8px] text-gray-500 font-medium mb-0">
                <Link to={`${SITELINK.COMMUNITIES}/${post?.community}`}>{post?.community}</Link>
              </p>
              <div className="relative">
                <p className="text-gray-500 top-[-9px] absolute">.</p>
              </div>
              <p className="text-[8px] text-gray-400 font-medium mb-0 ml-1">{post?.created_on}</p>
            </div>
          </div>
        </div>

        {/* isBoosted ads area */}
        <div>
          <div className="className='flex justify-end w-full">
            <button className="flex justify-center w-full" onClick={handleOpenSetup}>
              <LuMoreHorizontal className="text-xl text-gray-800" />
            </button>
          </div>

          {post?.is_boosted && (
            <button className="flex justify-end rounded-lg bg-white border border-gray-300 font-bold text-gray-500 px-1 border text-[8px]">
              Sponsored
            </button>
          )}
        </div>
      </div>

      {/* feed article */}
      <div className="text-[0.9rem] px-3 mb-3 text-black font-[400] mt-2">
        <>
          {truncateText(post?.content ?? '', 135)}{' '}
          {post?.content?.length > 134 ? (
            <Link
              to={SITELINK.VIEW_POSTS+'/'+post.post_id}
              className="text-gray-600 mt-1 mb-3 text-xs whitespace-nowrap"
            >
              See more
            </Link>
          ) : (
            <></>
          )}
        </>
      </div>

      {/* audio area */}
      {post?.audio && (
        <div className="px-3 w-full pb-3">
          <audio controls src={post?.audio} className="w-full custom-audio-player"></audio>
        </div>
      )}

      {/* image caption area */}
      {post?.images?.length > 0 && (
        <div
          className={`relative flex justify-center items-center overflow-hidden bg-cover bg-center max-h-[300px]`}
          style={{
            gridTemplateColumns: `repeat(${Math.min(post?.images?.length, 4)}, minmax(0, 1fr))`,
          }}
        >
          {post?.images?.map((image: string, index: number) => (
            <LazyLoad height={200} offset={1000} once key={index}>
              <div
                className="absolute inset-0 bg-cover bg-center blur-3xl"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <img src={image} className="relative w-full z-10" alt={`post_image ${index}`} />
            </LazyLoad>
          ))}
        </div>
      )}

      <div className="px-3 pb-3 border-t border-gray-50">
        {/* action buttons */}
        <div className="flex justify-between gap-2 mt-2 font-medium text-[12px]">
          {/* like button */}
          <button
            className={`flex items-center bg-gray-100 rounded-full px-3 py-2 ${
              post?.reacted_to_post ? 'text-custom-600 ' : ''
            }`}
            onClick={() => handleLike(post?.post_id)}
          >
            <div className="like-button">
              <div className="heart-bg">
                <div className={`heart-icon ${post?.reacted_to_post && 'liked'}`}></div>
              </div>
            </div>

            {/* figure round-up to K, M, B... */}
            {post?.reactions > 0 && <span className="pr-1">{post?.reactions}</span>}
            <span className="pr-1">Like{post?.reactions > 1 ? 's' : ''}</span>
          </button>

          {/* comment button */}
          <Link
            to={SITELINK.VIEW_POSTS+'/'+post.post_id}
            className={`flex items-center bg-gray-100 rounded-full px-3 py-2`}
          >
            <TbMessageCircle className="text-[1.1rem] text-gray-500" />

            {/* figure round-up to K, M, B... */}
            <span className="pl-1 pr-1">{post?.comments > 1 && post?.comments}</span>
            <span className="pr-1">Comment{post?.comments > 1 ? 's' : ''}</span>
          </Link>

          {/* share button */}
          <button
            className={`flex items-center bg-gray-100 rounded-full px-3 py-2`}
            onClick={() => handleShare(post?.post_id)}
          >
            <PiShareFatDuotone className="text-[1.1rem] text-gray-500" />

            {/* figure round-up to K, M, B... */}
            <span className="pr-1">Share</span>
          </button>
        </div>
      </div>

      {/* other options button */}
      <Modal isOpen={openSetup} onClose={handleOpenSetup}>
        <div className="text-center font-medium text-sm w-[80vw] shadow-2xl">
          <button className="border-b px-8 py-4 block w-full">Copy link</button>
          <button className="border-b px-8 py-4 block w-full">Repost post</button>

          <button
            className="border-b px-8 py-4 block w-full"
            onClick={() => handleSave(post?.post_id)}
          >
            {post?.bookmarked_post ? 'Remove from likes' : 'Add to likes'}
          </button>

          {post.creator.email === user.email && (
            <button
              className="border-b px-8 py-4 block w-full"
              onClick={() => handleDelete(post?.post_id)}
            >
              Delete post
            </button>
          )}

          <Link to={SITELINK.VIEW_POSTS+'/'+post?.post_id} className="border-b px-8 py-4 block w-full">
            View post
          </Link>

          <button
            className="border-b px-8 py-4 block w-full"
            onClick={() => handleShare(post?.post_id)}
          >
            Share post
          </button>

          <button
            className="text-red-600 font-semibold border-b px-8 py-4 block w-full rounded-b-2xl"
            onClick={handleOpenSetup}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PostCard;
