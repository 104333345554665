// read-more article on react-query optimistic-updates
// https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates

import { useMutation } from '@tanstack/react-query';
import apiClient from '../ApiClient';

export const getPost = async (id: number): Promise<any> => {
  const res = await apiClient.get('post/'+id);
  return res.data;
};

export const useGetFeed = () => {
  return useMutation({
    mutationFn: getPost,
  });
};
