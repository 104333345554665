import { LayoutProps } from '../app-schemas/common';
import GlobalLayout from './GlobalLayout';

const AuthLayout = ({ children, title = '' }: LayoutProps) => (
  <GlobalLayout title={title}>
    <div className="flex min-h-screen bg-white">
      <div className="w-7/12 bg-gray-900 hidden"></div>

      <div className="w-full lg:w-6/12 px-6 h-screen overflow-y-scroll">
        <div className="flex flex-col items-start mt-16">
          <h4 className="text-[2.5rem] font-[800] text-custom-600 playwrite-fr-trad">
            Catapot<span className='text-gray-600'>.</span>
          </h4>
        </div>

        <div className="w-full max-w-md">{children}</div>
      </div>
    </div>
  </GlobalLayout>
);

export default AuthLayout;
