import { useState, useCallback } from "react";
import { ZodSchema } from "zod";

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

const useFormInput = <T extends Record<string, string | number | boolean>>(
  initialState: T,
  schema: ZodSchema<T>
): [
  T,
  (e: ChangeEvent) => void,
  Partial<Record<keyof T, string>>,
  (errors: Partial<Record<keyof T, string>>) => void,
  (id: keyof T, value: T[keyof T]) => void,
  () => void,
] => {
  const [state, setState] = useState<T>(initialState);
  const [errors, setErrors] = useState<Partial<Record<keyof T, string>>>({});

  const handleValidation = useCallback(
    (field: keyof T, value: T[keyof T]) => {
      const validationResult = schema.safeParse({ ...state, [field]: value });
      if (!validationResult.success) {
        const validationErrors = validationResult.error.flatten().fieldErrors;
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: validationErrors[field]?.[0] || "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    },
    [schema, state]
  );

  const handleChange = useCallback(
    (e: ChangeEvent) => {
      const { id, value } = e.target;
      setState((prevState) => ({
        ...prevState,
        [id]: value,
      }));
      handleValidation(id as keyof T, value as T[keyof T]);
    },
    [handleValidation]
  );

  const handleCustomChange = useCallback(
    (id: keyof T, value: T[keyof T]) => {
      setState((prevState) => ({
        ...prevState,
        [id]: value,
      }));
      handleValidation(id, value);
    },
    [handleValidation]
  );

  const setValidationErrors = useCallback(
    (errors: Partial<Record<keyof T, string>>) => {
      setErrors(errors);
    },
    []
  );

  const resetForm = () => {
    setState(initialState);
    setErrors({});
  };

  return [state, handleChange, errors, setValidationErrors, handleCustomChange, resetForm];
};

export default useFormInput;
