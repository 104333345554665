import { LayoutProps } from '../app-schemas/common';
import BottomNav from './~includes/BottomNav';
import TopNav from './~includes/TopNav';
import GlobalLayout from './GlobalLayout';

document.body.classList.remove('overflow-hidden');
const AppLayout = ({ children, title = '' }: LayoutProps) => (
  <GlobalLayout title={title}>
    <TopNav />
    <div className="w-full mt-[50px] pt-[1px] bg-[#E1F0F8F7] ">{children}</div>
    <BottomNav />
  </GlobalLayout>
);

export default AppLayout;
