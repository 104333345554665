export const apiRoutes = {
   FetchConversations: '/conversations',
   FetchConversation: '/fetch-conversation',
   FetchConversationMessages: '/messages', 
   SendMessages: '/message/create',
   FetchCommunities: '/community',
   FetchMyCommunities: '/community/active',
   JoinCommunity: '/community/join',
   FetchCommunitiesDetails: '/community/details',
   FetchMembers: '/community/members',
   CreateCommunity: '/community/create',
   FetchCommunityActivity: '/community/activities',
   UpdateSetting: '/settings/update',
   FetchSetting: '/settings'
}
    