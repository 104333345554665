import { useState } from 'react';
import { LuArrowRight, LuCamera } from 'react-icons/lu';
import { useFetchFeeds } from '../../api-services/feeds/useFetchFeeds';
import { Community } from '../../app-schemas/community/community.interface';
import { UserInterface } from '../../app-schemas/profile.schema';
import AppLayout from '../../layouts/AppLayout';
import { getUser } from '../../app-utils';
import CommunityCard from './home-components/CommunityCard';
import WelcomeMessage from './home-components/WelcomeMessage';
import { Link } from 'react-router-dom';
import { SITELINK } from '../../app-routes/Links';
import { useRecommendedCommunities } from '../../api-services/community/useRecommendedCommunities';
import community_api from '../../api-services/community/community.services';
import { getFirstLetters } from '../../app-utils';
import PostCard from './home-components/PostCard';
import { Loader } from '../../assets/custom-icons';

// main-component-ui
const Home = () => {
  const user: UserInterface = getUser(); // getUser from localstorage

  // component use state;
  const [isWelcomeMssg, setIsWelcomeMssg] = useState<boolean>(true);

  /* react-query;  */
  const recommendedCommunities = useRecommendedCommunities();
  const posts = useFetchFeeds();

  /* Join community function */
  const handleJoinCommunity = async (id: number) => {
    try {
      const res = await community_api.JoinCommunity(id);

      console.log(res);

      if (res?.data?.status) {
        // tinyAlert('success', 'Success');
        recommendedCommunities.refetch();
      }
    } catch (error) {
      // tinyAlert('error', 'An error occured!');
    } finally {
      console.log('dd');
    }
  };

  // fn to geolocate user. - use axios

  if (recommendedCommunities.isLoading || posts.isLoading) {
    return (
      <AppLayout title="Catapot - Home">
        <div className="flex py-5 justify-center items-center h-[90vh]">
          <img src={Loader} alt="loader" className="h-[20px] w-[20px]" />
        </div>
      </AppLayout>
    );
  }

  /* Return && Render UI */
  return (
    <AppLayout title="Catapot - Home">
      {/* make a new post section */}
      <section className="relative bg-white border-t border-gray-300 shadow-xl px-2 py-2 flex gap-1 item-center justify-center">
        <Link to={SITELINK.MY_PROFILE} className="w-[50px] block">
          {user.profile_picture_url ? (
            <div className="flex-grow flex flex-col items-center">
              <div className="overflow-hidden flex justify-center">
                <img
                  alt="profile"
                  src={user?.profile_picture_url}
                  className="object-cover rounded-full w-[40px] h-[40px] bg-gray-200 shadow-sm overflow-hidden"
                />
              </div>
            </div>
          ) : (
            <span className="block w-[40px] h-[40px] bg-[#223f64] rounded-full border border-green-100">
              <h3 className="text-white text-[1.4rem] uppercase font-[600] relative top-0.5 left-2.5">
                {getFirstLetters(user?.first_name ?? user?.username)}
              </h3>
            </span>
          )}
        </Link>

        <Link
          to={SITELINK.COMPOSER}
          className="text-sm p-2 px-4 w-full border-gray-300 text-start text-gray-600 font-[400] border rounded-[50px]"
        >
          Have something to say?
        </Link>

        <Link
          to={SITELINK.COMPOSER}
          className="text-[1.4rem] text-gray-500 absolute right-[18px] top-[12px] bg-white rounded-[50px] bg-white p-1 rounded-full"
        >
          <LuCamera />
        </Link>
      </section>

      {/* ! new account Welcome message */}
      {user?.residency_city && isWelcomeMssg && (
        <WelcomeMessage user={user} onClose={() => setIsWelcomeMssg(false)} />
      )}

      {/*  recommended community list/action btn  */}
      <section
        className={`bg-white mt-2 border shadow-sm ${
          !recommendedCommunities?.data?.length && 'hidden'
        }`}
      >
        {/* label & close icon area */}
        <div className="font-[500] flex justify-between text-gray-700 pt-3 px-3">
          <span className="text-sm font-semibold mt-1">Find communities</span>
        </div>

        {/* community area */}
        <div className="search-item-filter w-full flex overflow-x-scroll scroll-smooth scroll-style mt-2 pb-5">
          {recommendedCommunities.isLoading ? (
            /* UI for lazy loding */
            <div className="recommended-communities-lazy-loding-ui"></div>
          ) : (
            <>
              {!!recommendedCommunities?.data?.length &&
                recommendedCommunities?.data?.map((community: Community, index: number) => (
                  <CommunityCard
                    key={index}
                    community={community}
                    onClick={handleJoinCommunity}
                    title="join"
                    icon={false}
                    className="max-w-[40vw] ml-3"
                  />
                ))}
            </>
          )}

          <Link
            to={SITELINK.MORE_COMMUNITIES}
            className="bg-[#fff] flex custom-min-width justify-center items-center p-6 px-2"
          >
            <div className="flex flex-col items-center gap-2 text-center">
              <button className="justify-center flex flex-col item-center mt-1 mx-5">
                <LuArrowRight className="text-[3rem] bg-custom-600 text-white font-semibold py-2 rounded-full shadow-xl" />
              </button>

              <p className="justify-center text-xs font-semibold mt-2 text-gray-600">Find More</p>
            </div>
          </Link>
        </div>
      </section>

      {/* community feeds section */}
      <section className="mb-20">
        {posts?.data?.length > 0 &&
          posts?.data?.map((post: any, index: number) => (
            <PostCard key={index} index={index} post={post} />
          ))}

        <div className="flex py-5 justify-center">
          <img src={Loader} alt="loader" className="h-[20px] w-[20px]" />
        </div>
      </section>
    </AppLayout>
  );
};

export default Home;
