import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './api-services/process';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Create a client
const queryClient = new QueryClient();
const clientId = GOOGLE_CLIENT_ID;

root.render(
  <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider clientId={clientId || ''}>
      <App />
    </GoogleOAuthProvider>

    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>,
);

reportWebVitals();
console.log('😆😆😆😆😆');


// document.addEventListener('contextmenu', function(event) {
//   event.preventDefault();
// });

document.addEventListener('copy', function(event) {
  event.preventDefault();
});

document.addEventListener('cut', function(event) {
  event.preventDefault();
});

// document.addEventListener('paste', function(event) {
//   event.preventDefault();
// });