// read-more article on react-query optimistic-updates
// https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FeedsQueryKeys } from './feeds.keys';
import apiClient from '../ApiClient';
import { PostInterface } from '../../app-schemas/posts/post.schema';

const likePost = async (id: number): Promise<any> => {
  const data = { community_post_id: id };
  const res = await apiClient.post('react', data);
  return res.data;
};

export const useLikeFeeds = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: likePost,
    onMutate: async (post_id) => {
      await queryClient.cancelQueries({ queryKey: FeedsQueryKeys.all });

      const previousData: any = queryClient.getQueryData(FeedsQueryKeys.all);

      if (!previousData) return { previousData };

      // Optimistically update the post in the cache
      const updatedData = previousData?.map((post: PostInterface) =>
        post.post_id === post_id
          ? {
              ...post,
              reacted_to_post: !post.reacted_to_post,
              reactions: post.reacted_to_post ? post.reactions - 1 : post.reactions + 1,
            }
          : post,
      );

      queryClient.setQueryData(FeedsQueryKeys.all, updatedData);

      // Return context with previous data in case of rollback
      return { previousData };
    },
    onError: (err, newUser, context?: any) => {
      // Rollback to the previous data on error
      if (context?.previousData) {
        queryClient.setQueryData(FeedsQueryKeys.all, context.previousData);
      }
    },
  });
};
