export enum RES_MSSG {
  EMAIL_NOT_VERIFIED = 'Email not verified',
  INCORRECT_CREDENTIALS = 'Incorrect credentials',
  SUCCESS = 'success',
  VERIFIED = 'verified',
  PROFILE_UPDATED = 'profile updated',

}


export enum FEED_MSSG {
  EMAIL_NOT_VERIFIED = 'Kindly verify your email address to continue',
  INCORRECT_CREDENTIALS = 'Your email and password did not match.',
  CODE_SENT = 'Verification code sent to your email address',
  OTP_LENGTH_SHORT = 'OTP must be six characters in length.',
  VERIFIED = 'Your OTP & email is verified',
  RESET_OK = 'Your password reset is successful, please login with your new password',
}

