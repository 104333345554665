import AppLayout from '../../layouts/AppLayout';
import { Avatar, EmojiIcon } from '../../assets/custom-icons';
import { LuPhoneCall, LuVideo, LuSend, LuArrowLeft } from 'react-icons/lu';
import { truncateText } from '../../app-utils';
import EmojiPicker from 'emoji-picker-react';
import { useState, useEffect, useRef } from 'react';

import { useConversations } from '../../api-services/messages/use-conversations';
import { useConversation } from '../../api-services/messages/use-conversation';
import { useConversationMessages } from '../../api-services/messages/use-conversation-messages';
import { useCreateMesage } from '../../api-services/messages/use-send-message';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { MessagesValue, ConversationValue } from '../../app-schemas/community/community.interface';

// main-component-ui
const Messages = () => {
  document.body.classList.remove('overflow-hidden');
  const [showPicker, setShowPicker] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [content, setContent] = useState('');
  const [receipientPhoto, setReceipientPhoto] = useState('');
  const [receipientName, setReceipientName] = useState('');
  const [lastMessage, setLastMessage] = useState('');
  const [, /*receipient_id*/ setReceipientId] = useState<Number | any>(0);
  const pickerRef = useRef<HTMLDivElement | null>(null);

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location?.state as { memberId?: number; memberName?: string };
  const conversation = useConversations();
  const conversationId = useConversation();
  const conversationMessage = useConversationMessages(
    conversationId?.data?.data?.conversation_id ?? id,
  );

  const sendMessage = useCreateMesage();

  const onSubmit = async (data: {}) => {
    sendMessage.mutate(data);
  };

  useEffect(() => {
    if (sendMessage.isSuccess) {
      setContent('');
    }
  }, [sendMessage.isSuccess]);

  const handleClickOutside = (event: MouseEvent) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
      setShowPicker(false); // Close the picker if clicked outside
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  const sendMessages = () => {
    const data = {
      recipient_id: state?.memberId ?? 1,
      conversation_id: conversationId?.data?.data?.conversation_id,
      content: content,
    };
    onSubmit(data);
  };
  const handleEmojiSelect = (emoji: any) => {
    setContent((prevContent) => prevContent + emoji.emoji);
  };

  const handleBackClick = () => {
    setIsChatOpen(false);
  };
  return (
    <AppLayout title="Catapot - Messages">
      <div className="flex flex-col lg:flex-row">
        <div className={`w-full ${isChatOpen ? 'hidden' : 'block'}`}>
          <h2 className="z-50 px-2 font-medium text-center text-md bg-white shadow-lg border-b p-3">Messages</h2>

          
          {conversation.isSuccess &&
            conversation.data.data.length > 0 &&
            conversation.data.data.map((converse: ConversationValue, index: number) => (
              <div
                key={index}
                onClick={() => {
                  setReceipientName(converse.recipient_name);
                  setLastMessage(converse.last_updated);
                  setReceipientPhoto(converse.profile_picture);
                  setReceipientId(converse?.recipient_id);
                  setIsChatOpen(true);
                  navigate(`/message/view/${converse?.recipient_id}`);
                }}
                className="w-full flex cursor-pointer items-center justify-between gap-2  focus:bg-blue h-[65px] px-2"
              >
                <div className="w-full flex items-center gap-2">
                  <img
                    src={converse?.profile_picture}
                    alt=""
                    className="w-[42px] h-[42px] rounded-full"
                  />
                  <div className="">
                    <p className="font-lg font-medium text-xs m-0 flex gap-1 items-center">
                      {converse?.recipient_name}
                    </p>
                    <p className="text-xs flex gap-2 m-0 text-[#C2B9B9] w-[296px]">
                      {truncateText(converse.content, 30)}{' '}
                      <p className="text-xs"> &bull; {converse.last_updated}</p>
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {state?.memberId ||
        (conversationMessage?.data?.data?.length > 0 &&
          (conversationId?.data?.data?.conversation_id || id)) ? (
          <div
            className={`w-full flex flex-col bg-white lg:w-8/12 ${
              isChatOpen ? 'block' : 'hidden'
            } md:block`}
          >
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex justify-between px-1 md:px-4 items-center border-b border-gray-300 bg-white h-[68px]">
                <div
                  className={`text-blue-500 font-semibold text-sm flex ${
                    isChatOpen ? 'block' : 'hidden'
                  } md:hidden`}
                  onClick={handleBackClick}
                >
                  <LuArrowLeft className="text-xl" />
                  {/* <span>Back</span> */}
                </div>
                <div className="flex items-center gap-2">
                  <img
                    src={receipientPhoto ?? Avatar}
                    alt=""
                    className="w-[42px] h-[42px] rounded-full"
                  />
                  <div className="">
                    <p className="font-lg font-medium m-0 flex text-xs gap-1 items-center">
                      {state?.memberName ?? receipientName}
                    </p>
                    <p className="text-xs m-0 text-[#C2B9B9] ">{lastMessage}</p>
                  </div>
                </div>
                <div ref={pickerRef} className=" flex items-center justify-between md:w-[168px]">
                  <div className="flex  w-full justify-end items-center gap-3">
                    <LuPhoneCall className="text-xl" />
                    <LuVideo className="text-xl" />
                  </div>
                </div>
              </div>
              <div ref={pickerRef} className="flex flex-col gap-4 custom-scrollbar h-screen">
                <p className="text-center text-xs mt-2">Today</p>
                {conversationMessage.isSuccess &&
                  conversationMessage.data.data.length > 0 &&
                  conversationMessage.data.data.map((message: MessagesValue, index: number) => (
                    <div
                      key={index}
                      className={
                        message?.status === 'sent'
                          ? `flex flex-row-reverse justify-start gap-1 mr-5 ml-5 `
                          : `ml-5 mr-5 flex gap-1`
                      }
                    >
                      {/* <img
                        src={message?.status === 'sent' ? receipientPhoto : Avatar}
                        className="h-[32px] w-[32px] rounded-full"
                        alt=""
                      /> */}
                      <div
                        className={`flex flex-col gap-4 justify-between h-auto rounded-md p-2 ${
                          message?.status === 'sent'
                            ? `bg-gray-200 text-[#003049]`
                            : `bg-[#003049] text-white`
                        }`}
                      >
                        <p className="text-sm w-[auto]">{message?.message}</p>
                        {/* <p className="text-xs">{message?.created}</p> */}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {showPicker && (
              <div
                ref={pickerRef}
                className="absolute -bottom-48  w-full max-w-xs md:max-w-sm lg:max-w-md z-50  h-auto"
              >
                <EmojiPicker onEmojiClick={handleEmojiSelect} />
              </div>
            )}
            <form className="border border-[#B1A3A3] mt-4  mb-[65px] sm:mb-0">
              <div className="flex">
                <div className="relative w-full">
                  <div
                    onClick={() => setShowPicker(!showPicker)}
                    className=" cursor-pointer absolute inset-y-0 left-0 flex items-center pl-3"
                  >
                    <img src={EmojiIcon} alt="" className="" />
                  </div>

                  <input
                    onChange={(e) => setContent(e.target.value)}
                    value={content}
                    type="text"
                    id=""
                    className="block pl-12 pr-20 p-2.5 h-auto w-full z-20 text-sm text-gray-900 rounded-e-lg"
                    placeholder="Text here..."
                    required
                  />
                  <button
                    disabled={content === ''}
                    onClick={(e) => {
                      e.preventDefault();
                      sendMessages();
                    }}
                    type="submit"
                    className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-blue-500 rounded-e-lg border-l border-[#B1A3A3]"
                  >
                    <LuSend className="text-xl" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="relative top-[10px] py-3">
            <div className="text-center">
              <p className="text-xs flex flex-col mt-24">No message available</p>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default Messages;
