import {useQuery } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { CommunityQueryKeys } from './community.keys';
import { apiRoutes } from '../ApiRoutes';
import { useParams } from 'react-router-dom';
export function useCommunityMember() {
    const { id } = useParams();
  
    const getCommunityMemberFn = async () => {
      const response = await apiClient.get(`${apiRoutes.FetchMembers}/${id}`);
      return response.data;
    };
  
    return useQuery({
      queryKey: CommunityQueryKeys.member(Number(id)),
      queryFn: getCommunityMemberFn,
      retry: 1,
    });
  }
  