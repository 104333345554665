import {useQuery } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { CommunityQueryKeys } from './community.keys';
import { apiRoutes } from '../ApiRoutes';

const getCommunityFn = async () => {
  const response = await apiClient.get(apiRoutes.FetchMyCommunities);
  return response.data;
};

export function useCommunities() {
  return useQuery({
    queryKey: CommunityQueryKeys.all,
    queryFn: getCommunityFn,
  });
}