import React from "react";
import { LuLoader } from "react-icons/lu";

type ButtonComponentProps = {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  loading?: boolean;
};

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  type = "button",
  disabled = false,
  className = "",
  onClick,
  children,
  loading,
}) => {
  return (
    <button
      type={type}
      className={`inline-flex items-center justify-center whitespace-nowrap font-medium px-4 w-full  col-span-full shadow-lg  hover:shadow-xl disabled:bg-gray-200 disabled:text-gray-400 disabled:shadow-sm ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? <LuLoader className="text-xl animate-spin" /> : children}
    </button>
  );
};

export default ButtonComponent;
//#009a41 #f77f00