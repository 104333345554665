import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CryptoJS from 'crypto-js';

const MySwal = withReactContent(Swal);

// tiny-alert-export
export function tinyAlert(type: string = 'close', message: string = '') {
  toast.dismiss();
  switch (type) {
    case 'info':
      toast.info(message, {
        autoClose: 70000,
      });
      break;
    case 'success':
      toast.success(message, {
        autoClose: 70000,
      });
      break;
    case 'warning':
      toast.warning(message, {
        autoClose: 70000,
      });
      break;
    case 'error':
      toast.error(message, {
        autoClose: 70000,
      });
      break;
    case 'close':
      toast.dismiss();
      break;
    default:
      toast.error(message, {
        autoClose: 70000,
      });
  }
}

// full page- preloader
export function pageLoader(action: boolean = false) {
  if (action) {
    MySwal.fire({
      title: '',
      html:
        '<div style="font-size:12px"><img style="width: 25px; display:inline" src="' +
        process.env.PUBLIC_URL +
        '/loading-icon-red.gif" /></div>',
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
    });
  } else {
    MySwal.close();
  }
}

// return todays date YYYY-MM-DD
export const getCurrentDateFormatted = () => {
  const date = new Date();

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// Function to store JWT token in session storage
export const storeToken = (token: string) => {
  localStorage.setItem('aa_public', encryptDataWithIV(token));
};

// Function to get JWT token from session storage
export const getToken = () => {
  const token = localStorage.getItem('aa_public') || '';
  return decryptDataWithIV(token);
};

// Function to remove JWT token from session storage
export const removeToken = () => {
  localStorage.removeItem('aa_public');
};
export const destroySession = () => {
  localStorage.clear();
  window.location.reload();
};

// Function to store USER in session storage
export const storeUser = (data: any) => {
  const json_data = JSON.stringify(data);
  localStorage.setItem('aa_data', encryptDataWithIV(json_data));
};

// Function to get USER from session storage
export const getUser = () => {
  const json_data: any = localStorage.getItem('aa_data');
  return JSON.parse(decryptDataWithIV(json_data));
};

// Function to remove USER from session storage
export const removeUser = () => {
  localStorage.removeItem('aa_data');
};

const secretKey = process.env.REACT_APP_AES_SECRET || '';

export const encryptData = (data: any) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

export const decryptData = (cipherText: any) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const encryptDataWithIV = (data: string): string => {
  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  const encryptedString = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
  return encryptedString;
};

export const decryptDataWithIV = (cipherText: string): string => {
  const data = CryptoJS.enc.Base64.parse(cipherText);
  const iv = CryptoJS.lib.WordArray.create(data.words.slice(0, 4));
  const ciphertext = CryptoJS.lib.WordArray.create(data.words.slice(4));
  const encrypted = CryptoJS.lib.CipherParams.create({
    ciphertext: ciphertext,
    iv: iv,
  });

  const decrypted = CryptoJS.AES.decrypt(encrypted, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export function getFirstLetters(inputString: string) {
  const words: string[] = inputString ? inputString.split(' ') : ['C'];
  const initials = words.map((word) => word.charAt(0));
  return initials.join('');
}

export const formatDateTime = (datetime: Date) => {
  const dateString = datetime;
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  return formattedDate.replace(/,/g, '');
};

export function getMonthsFromDates(dateList: string[]) {
  const months = [];
  for (const dateString of dateList) {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' }) + ', ' + date.getFullYear();
    months.push(month);
  }
  return months;
}

// Utility helpers == below
export function formatDate(date: string, format?: string) {
  let d = new Date(date);

  if (format === 'time') {
    return d.toLocaleTimeString('en-US');
  } else if (format === 'date') {
    let y = d.getFullYear();
    let m = d.getMonth();
    let dd = d.getDate();
    let months_of_year = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    return `${months_of_year[m] + ' ' + dd + ', ' + y}`;
  } else {
    return d.toDateString() + ' ' + d.toLocaleTimeString('en-US');
  }
}

export function twoDecimalPlace(value: number) {
  if (value) return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return '0';
}

export function twoDP(value: number) {
  if (value) return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return '0';
}

export const truncateText = (text: any, maxLength: any) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

export function convertToThousands(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  const { name, value } = e.target;
  // const numericInput = value;
  // const inputValue = numericInput.trim();
  const sanitizedValue = value.replace(/,/g, '').replace(/\.+/g, '.');
  const numericValue = parseFloat(sanitizedValue);

  // value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  // Check !input
  // if (!isNaN(numericValue)) {
  //     const formattedValue = (numericValue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  //     setFormData({  ...formData, [name]: formattedValue,});
  // }
  // else {
  //     setFormData({  ...formData, [name]: '0',});
  // }
  // if (!isNaN(numericValue)) {
  //     setFormData({  ...formData, [name]: numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),});
  // }

  if (!isNaN(numericValue)) {
    // Format the number as thousands with two decimal places
    // const formattedValue = numericValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const decimalPosition = sanitizedValue.indexOf('.');

    // Format the number as thousands with two decimal places
    const formattedValue = numericValue.toLocaleString('en-US', {
      minimumFractionDigits: decimalPosition >= 0 ? 2 : 0,
      maximumFractionDigits: 2,
    });

    // Update the form data with the formatted value
    // setFormData({ ...formData, [name]: formattedValue });
    console.log(formattedValue);
  } else {
    // If the input is not a valid number, set the form data with an empty string
    // setFormData({ ...formData, [name]: '' });
    console.log('');
  }

  console.log(
    numericValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    name,
  );
}

export function generateRandomHexColor() {
  const r = Math.floor(Math.random() * 100); // Limit to darker red values
  const g = Math.floor(Math.random() * 100); // Limit to darker green values
  const b = Math.floor(Math.random() * 100); // Limit to darker blue values

  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  return `#${hexR}${hexG}${hexB}`;
}
