import { useEffect, useState } from 'react';
import { LuArrowLeft, LuSearch, LuX, LuArrowRight } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import community_api from '../../api-services/community/community.services';
import { useSearchCommunities } from '../../api-services/contents/useSearchCommunities';
import { SITELINK } from '../../app-routes/Links';
import { Community } from '../../app-schemas/community/community.interface';
import { truncateText } from '../../app-utils';
import { CommunityImage, Loader } from '../../assets/custom-icons';

// main-component-ui
const Search = () => {
  document.body.classList.remove('overflow-hidden');
  const navigate = useNavigate();

  const searchHook = useSearchCommunities();
  const [filterBy, setFilterBy] = useState('Communities');

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchResult, setSearchResult] = useState<any>([]);
  const [searchResultLoading, setsDarchResultLoading] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (debouncedSearch.length > 2) {
      searchItem();
    }

    // eslint-disable-next-line
  }, [debouncedSearch]);

  // Function to handle search logic
  const searchItem = async () => {
    if (debouncedSearch.length < 3) return;

    setsDarchResultLoading(true);

    await searchHook.mutate(debouncedSearch, {
      onSuccess: (data) => {
        setSearchResult(data);
        setsDarchResultLoading(false);
      },
      onError: (err) => {
        console.error('Error searching:', err);
      },
    });
  };

  /* Join community function */
  const handleJoinCommunity = async (id: number) => {
    try {
      const res = await community_api.JoinCommunity(id);

      console.log(res);

      if (res?.data?.status) {
        // tinyAlert('success', 'Success');
        // recommendedCommunities.refetch();
        // navigage to the community;
        navigate(SITELINK.COMMUNITY_ACTIVITIES + '/' + id);
      }
    } catch (error) {
      // tinyAlert('error', 'An error occured!');
    } finally {
      console.log('dd');
    }
  };

  return (
    <section>
      {/* top lead area and search area */}
      <div className="fixed top-0 w-full z-50 shadow-sm">
        <div className="relative px-2 py-3 bg-white text-white">
          {/* go back to home */}
          <div className="absolute top-3 left-1">
            <Link to={SITELINK.HOME} className="font-semibold text-sm flex p-2.5 px-4">
              <LuArrowLeft className="text-2xl text-gray-700" />
            </Link>
          </div>

          {/* search input */}
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            autoFocus={true}
            type="text"
            placeholder="Search..."
            className="text-sm p-3 pl-[45px] pr-[50px] px-4 w-full border-gray-300 text-start text-gray-600 font-[500] border rounded-lg"
          />

          {/* search icon && search cancel */}
          {search.length < 2 ? (
            <span className={`absolute top-[1rem] right-4 px-1 py-2 font-medium`}>
              <LuSearch className="text-xl text-gray-400" />
            </span>
          ) : (
            <button
              onClick={() => setSearch('')}
              type="button"
              className={`absolute top-[1rem] right-4 px-1 py-2 text-sm font-medium`}
            >
              <LuX className="text-xl text-gray-500" />
            </button>
          )}
        </div>

        <div className="search-item-filter border-b shadow-sm bg-white flex gap-6 text-xs font-medium overflow-scroll px-3">
          <button
            onClick={() => setFilterBy('Communities')}
            className={` ${
              filterBy === 'Communities' &&
              'text-custom-600 rounded-b-[2px] border-custom-600 border-b border-b-[3px] text-black animate-fast-fade-in'
            } hover:shadow-xl py-1 whitespace-nowrap`}
          >
            Communities
          </button>

          <button
            onClick={() => setFilterBy('People')}
            className={`
            ${
              filterBy === 'People' &&
              'text-custom-600 rounded-b-[2px] border-custom-600 border-b border-b-[3px] text-black animate-fast-fade-in'
            } hover:shadow-xl py-1 whitespace-nowrap `}
          >
            People
          </button>

          <button
            onClick={() => setFilterBy('Posts')}
            className={`${
              filterBy === 'Posts' &&
              'text-custom-600 rounded-b-[2px] border-custom-600 border-b border-b-[3px] text-black animate-fast-fade-in'
            } hover:shadow-xl py-1  whitespace-nowrap `}
          >
            Posts
          </button>

          <button
            onClick={() => setFilterBy('Market')}
            className={`${
              filterBy === 'Market' &&
              'text-custom-600 rounded-b-[2px] border-custom-600 border-b border-b-[3px] text-black animate-fast-fade-in'
            } hover:shadow-xl py-1  whitespace-nowrap `}
          >
            Market Place
          </button>

          <button
            onClick={() => setFilterBy('Rides')}
            className={` ${
              filterBy === 'Rides' &&
              'text-custom-600 rounded-b-[2px] border-custom-600 border-b border-b-[3px] text-black animate-fast-fade-in'
            } hover:shadow-xl  py-1  whitespace-nowrap `}
          >
            Rides
          </button>

          <button
            onClick={() => setFilterBy('Jobs')}
            className={`${
              filterBy === 'Jobs' &&
              ' rounded-b-[2px] text-custom-600 border-custom-600 border-b border-b-[3px] text-black animate-fast-fade-in'
            } hover:shadow-xl py-1 whitespace-nowrap `}
          >
            Jobs
          </button>
        </div>
      </div>

      {/* search results */}
      <div className="relative top-[80px] py-5 px-3">
        {/* community area */}
        <div className="flex flex-col w-full mt-2 pb-2 mb-12">
          {searchResultLoading ? (
            <div className="h-[70vh] flex justify-center items-center">
              <img src={Loader} alt="pre-loader" className="w-[20px] h-[20px]" />
            </div>
          ) : (
            <>
              {!!searchResult?.data?.length ? (
                searchResult?.data?.map((community: Community, index: number) => (
                  <div
                    className="bg-white border px-3 py-3 rounded-lg gap-1 justify-between items-center flex mb-2"
                    key={index}
                  >
                    <div className="flex-grow flex">
                      <div className="overflow-hidden flex justify-center w-[50px] mt-1">
                        <img
                          src={
                            !!community.cover_image_url ? community.cover_image_url : CommunityImage
                          }
                          alt="community icon"
                          className="object-cover rounded-full border border-gray-50 w-[40px] h-[40px] shadow-sm overflow-hidden"
                        />
                      </div>

                      <div className="px-3 w-full text-xs py-2">
                        <h3 className="font-semibold mb-0">{truncateText(community.name, 25)}</h3>

                        <p className="text-gray-500 mt-0">
                          {`${community.city}, ${community.country}`}
                        </p>

                        <p className="text-gray-500 mt-0 hidden">
                          {`${community.members} member${community.members > 1 ? 's' : ''}`}
                        </p>
                      </div>
                    </div>

                    <div className="flex-grow flex justify-end">
                      <button
                        onClick={() => handleJoinCommunity(community.id)}
                        className="justify-center p-2 rounded-lg  shadow-sm font-semibold text-gray-200 bg-gray-200"
                      >
                        <LuArrowRight className='text-2xl text-bold text-custom-600' />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <p className="text-xs flex flex-col mt-24">No search result available</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Search;
