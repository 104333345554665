import {useQuery } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { CommunityQueryKeys } from './community.keys';
import { apiRoutes } from '../ApiRoutes';
import { useParams } from 'react-router-dom';
export function useCommunityActivity() {
    const { id } = useParams();
  
    const getCommunityActivityFn = async () => {
      const response = await apiClient.get(`${apiRoutes.FetchCommunityActivity}/${id}`);
      return response.data;
    };
  
    return useQuery({
      queryKey: CommunityQueryKeys.activity(Number(id)),
      queryFn: getCommunityActivityFn,
      retry: 1,
    });
  }
  