//! app wrapper for dynamic-title
import { LayoutProps } from '../app-schemas/common';
import React, { useEffect } from 'react';

interface HeadProps {
  title: string;
}

const Head: React.FC<HeadProps> = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
};

const GlobalLayout = ({ children, title = 'Catapot | Welcome' }: LayoutProps) => (
  <>
    <Head title={title} />
    {children}
  </>
);

export default GlobalLayout;
