import React from 'react';
import APPRoutes from './app-routes/index';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <APPRoutes />
      <ToastContainer position="top-left" />

        {/* <section className="fixed bottom-[80px] left-0 right-0">
          <div className="flex justify-center">
            <span className="bg-gray-600 px-5 py-1 text-sm text-white rounded-3xl">{`where i want to show my notification`}</span>
          </div>
        </section> */}
    </>
  );
}

export default App;
