import {useQuery } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { MessageQueryKeys } from './message-query-key';
import { apiRoutes } from '../ApiRoutes';
export function useConversationMessages(id: number) {
  const getConversationMessages = async (conversationId: number) => {
    const response = await apiClient.get(`${apiRoutes.FetchConversationMessages}?conversation_id=${conversationId}`);
    return response.data;
  };

  return useQuery({
    queryKey: MessageQueryKeys.message(id),
    queryFn: () => getConversationMessages(id),
    retry: 1,
  });
}