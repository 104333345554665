import { LuCamera } from 'react-icons/lu';
import { TbCategoryPlus, TbMessage, TbSettingsCog } from 'react-icons/tb';
import { Link, useLocation } from 'react-router-dom';
import { SITELINK } from '../../app-routes/Links';
import { GoHomeFill } from "react-icons/go";

// main-component-ui
const BottomNav = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <section className="sm:hidden">
      {/* bottom nav as component */}
      <div className="z-10 shadow-xl grid grid-cols-4 gap-4 justify-between text-gray-700 text-[1.4rem] fixed bg-gray-50 w-full bottom-0 py-2 border-t">
        <Link
          to={SITELINK.HOME}
          className={`text-center ${
            currentPath === SITELINK.HOME ? 'text-custom-600' : 'text-gray-700'
          }  pb-2`}
        >
          <GoHomeFill  className="w-full" />
          <div className="w-full text-xs font-semibold" style={{ fontSize: '10px' }}>
            Home
          </div>
        </Link>

        <Link
          to={SITELINK.MESSAGES}
          className={`text-center ${
            currentPath === SITELINK.MESSAGES ? 'text-custom-600' : 'text-gray-700'
          }  pb-2`}
        >
          <TbMessage className="w-full" />
          <div className="w-full text-xs font-semibold" style={{ fontSize: '10px' }}>
            Messages
          </div>
        </Link>

        <Link
          to={SITELINK.COMPOSER}
          className={`text-center  ${
            currentPath === SITELINK.COMPOSER ? 'text-custom-600' : 'text-gray-700'
          }  pb-2 hidden`}
        >
          <LuCamera className="w-full" />
          <div className="w-full text-xs font-semibold" style={{ fontSize: '10px' }}>
            New
          </div>
        </Link>

        <Link
          to={SITELINK.COMMUNITIES}
          className={`text-center  ${
            currentPath === SITELINK.COMMUNITIES ? 'text-custom-600' : 'text-gray-700'
          }  pb-2`}
        >
          <TbCategoryPlus className="w-full" />
          <div className="w-full text-xs font-semibold" style={{ fontSize: '10px' }}>
            Communities
          </div>
        </Link>

        <Link
          to={SITELINK.PRIVACY_AND_SETTINGS}
          className={`text-center ${
            currentPath === SITELINK.PRIVACY_AND_SETTINGS ? 'text-custom-600' : 'text-gray-700'
          }  pb-2`}
        >
          <TbSettingsCog className="w-full" />
          <div className="w-full text-xs font-semibold" style={{ fontSize: '10px' }}>
            Settings
          </div>
        </Link>
      </div>
    </section>
  );
};

export default BottomNav;
