export const SearchQueryKeys = {
  all: ['search'],



  details: () => [...SearchQueryKeys.all, 'detail'],
  members: () => [...SearchQueryKeys.all, 'member'],
  activities: () => [...SearchQueryKeys.all, 'activity'],
  detail: (id: number) => [...SearchQueryKeys.details(), id],
  member: (id: number) => [...SearchQueryKeys.members(), id],
  activity: (id: number) => [...SearchQueryKeys.activities(), id],
  pagination: (page: number) => [...SearchQueryKeys.all, 'pagination', page],
  infinite: () => [...SearchQueryKeys.all, 'infinite'],
};
