import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../components/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/tabs';
import { Avatar } from '../../assets/custom-icons/index';
import { SITELINK } from '../../app-routes/Links';
import { LuArrowLeft } from 'react-icons/lu';
import {
  WhatsappImage,
  WeChatImage,
  TelegramImage,
  MessageImage,
} from '../../assets/custom-icons/index';
import { Link } from 'react-router-dom';
import { useCommunityDetails } from '../../api-services/community/use-community-details';
import { useCommunityMember } from '../../api-services/community/use-community-members';
import { useCommunityActivity } from '../../api-services/community/use-community-activity';
import { Member } from '../../app-schemas/community/community.interface';
import AppLayout from '../../layouts/AppLayout';
import PostCard from '../home/home-components/PostCard';

const CommunityDetails = () => {
  const userCommunityDetails = useCommunityDetails();
  const members = useCommunityMember();
  const activity = useCommunityActivity();

  // const navigate = useNavigate();

  const chatOnWhatsapp = (number: number | null) => {
    const url = `https://wa.me/${number}`;
    window.open(url, '_blank'); // Open in a new tab
  };
  const handleTelegramClick = (number: number | null) => {
    const url = `tg://openchat?phone=${number}`;
    window.open(url, '_blank'); // Open in a new tab
  };

  return (
    <AppLayout title="Catapot - Community Details">
      <div className="flex justify-between px-3 py-3 bg-white text-custom-600">
        <div className="flex gap-4">
          <Link to={SITELINK.COMMUNITIES} className="font-semibold text-sm flex mt-[5px]">
            <LuArrowLeft className="text-xl" />
          </Link>

          <p className="mt-1 text-sm font-medium">Community Details</p>
        </div>
      </div>
      <section className="bg-white mt-1 border shadow-sm p-3 h-screen">
        <div className="min-h-screen py-5 rounded-md bg-white flex flex-col gap-2 lg:gap-3 lg:w-[90%] mx-auto box-border">
          {userCommunityDetails.isSuccess && (
            <>
              {/* <div
            className="text-blue-500 font-semibold text-sm flex gap-1"
            onClick={handleBackClick}
          >
            <LuArrowLeft className="text-xl" />
            <span>Back</span>
          </div> */}
              <div className=" rounded-md bg-white flex flex-col items-center gap-2 lg:gap-3 lg:w-[80%] mx-auto box-border">
                <img
                  src={userCommunityDetails.data.data.cover_image_url ?? Avatar}
                  className="w-[52px] h-[52px] rounded-full"
                  alt=""
                />
                {userCommunityDetails.isSuccess && (
                  <h2 className="text-xl font-bold">{userCommunityDetails.data.data.name}</h2>
                )}
                <Tabs defaultValue="about" className="w-full">
                  <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="about">About Group</TabsTrigger>
                    <TabsTrigger value="activity">Activities</TabsTrigger>
                    <TabsTrigger value="member">Member</TabsTrigger>
                  </TabsList>
                  <TabsContent value="about">
                    <Card>
                      <div className="flex flex-col lg:flex-row">
                        <div>
                          <CardHeader>
                            <CardTitle className="text-[#0A32AA]">Description</CardTitle>
                            <CardDescription className="lg:w-[480px]">
                              {userCommunityDetails.data.data.description
                                ? userCommunityDetails.data.data.description
                                : 'N/A'}
                            </CardDescription>
                          </CardHeader>
                          <CardContent className="space-y-2">
                            <CardTitle className="text-[#0A32AA]">Community Tag</CardTitle>
                            <div className="flex gap-5 flex-wrap items-center">
                              {userCommunityDetails.data.data?.community_tags?.map(
                                (tag: any, index: number) => (
                                  <button
                                    key={index}
                                    className="flex h-10 items-center px-4 text-sm  transition-colors  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100  aria-disabled:opacity-50 cursor-pointer w-[96px]  justify-center border border-orange-100 rounded-sm bg-white font-bold text-orange-100"
                                  >
                                    {tag}
                                  </button>
                                ),
                              )}
                            </div>
                          </CardContent>
                        </div>
                        <div>
                          <CardHeader className="flex flex-col gap-2">
                            <CardTitle className="w-full text-[#0A32AA]">
                              Community Information
                            </CardTitle>
                            <div className="flex flex-col gap-10">
                              <CardDescription className="w-full flex justify-between items-center">
                                <div className="flex gap-1 items-center">
                                  <img src={Avatar} className="w-[32px] h-[32px]" alt="" />
                                  <p className="text-xs">
                                    {userCommunityDetails.data?.data?.creator?.username ??
                                      userCommunityDetails.data?.data?.creator?.first_name}
                                  </p>
                                </div>
                                <p>Creator</p>
                              </CardDescription>
                              <CardDescription className="w-full flex justify-between items-center">
                                <p>Members</p>
                                <p>{userCommunityDetails.data.data.members}</p>
                              </CardDescription>
                              <CardDescription className="w-full  flex justify-between items-center">
                                <p>Created</p>
                                <p>{userCommunityDetails.data.data.created}</p>
                              </CardDescription>
                            </div>
                          </CardHeader>
                        </div>
                      </div>
                    </Card>
                  </TabsContent>
                  <TabsContent value="activity">
                    {activity?.data?.data?.length > 0 &&
                      activity?.data?.data?.map((post: any, index: number) => (
                        <PostCard key={index} index={index} post={post} />
                      ))}
                  </TabsContent>
                  <TabsContent value="member">
                    <div className="flex flex-col gap-2 sm:w-[80%] mx-auto lg:w-auto">
                      {members.isSuccess &&
                        members?.data?.data?.community_members?.map(
                          (member: Member, index: number) => (
                            <Card key={index} className="p-3 rounded-none bg-[#BEC2CE29]">
                              <div className="flex   gap-4 justify-between items-center">
                                <div className="flex gap-1 items-center">
                                  <div className="mb-2 flex items-center gap-3">
                                    <img src={Avatar} className="w-[32px] h-[32px]" alt="" />
                                    <div className="text-xs">
                                      <p>{member.name}</p>
                                      <p>
                                        {member.name ===
                                        userCommunityDetails.data?.data?.creator?.username
                                          ? 'Creator'
                                          : 'Member'}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="flex lg:justify-between gap-4 lg:gap-16 items-center">
                                  <img
                                    src={WhatsappImage}
                                    alt=""
                                    className="cursor-pointer"
                                    onClick={() => chatOnWhatsapp(member.whatsapp_number)}
                                  />
                                  <img
                                    src={TelegramImage}
                                    alt=""
                                    className="cursor-pointer"
                                    onClick={() => handleTelegramClick(member.phone_number)}
                                  />
                                  <img src={WeChatImage} alt="" />
                                  <Link
                                    to={`${SITELINK.VIEW_MESSAGES}/${member.user_id}`}
                                    state={{
                                      memberId: member.user_id,
                                      memberName: member.name,
                                    }}
                                  >
                                    <img src={MessageImage} alt="" />
                                  </Link>
                                </div>
                              </div>
                            </Card>
                          ),
                        )}
                    </div>
                  </TabsContent>
                </Tabs>
              </div>
            </>
          )}
        </div>
      </section>
    </AppLayout>
  );
};
export default CommunityDetails;
