import { z } from 'zod';

// common
export interface Profile {
  bio: string | null;
  email: string | null;
  first_name: string | null;
  gender: string | null;
  is_verified: boolean;
  is_onboarded: boolean;
  last_name: string | null;
  marital_status: string | null;
  nationality_country_id: string | null;
  phone_number: number | null | string;
  residency_city: string | null;
  residency_country_id: string | null;
  residency_postal_code: string | null;
  telegram_number: string | null;
  username: string | null;
  wechat_number: number | null;
  whatsapp_number: string | number | null;
}
export interface MembersLoginResponse {
  data: string;
  cookie?: string;
  message: string;
  status: string;
  otp: string[];
  is_onboarded?: boolean;
  user: Profile;
}

/**
 *[page 1] users-login
 */
export type MembersLoginType = {
  email: string;
  password: string;
};
export const MembersLoginDefault: MembersLoginType = {
  email: '',
  password: '',
};
export const MembersLoginSchema = z.object({
  email: z.string().email('Valid email address is required'),
  password: z.string().min(8, 'The password field must be at least 8 characters long.'),
});

/**
 *[page 2A] users-request-reset
 */
export type MembersRequestResetType = {
  email: string;
};
export const MembersRequestResetDefault: MembersRequestResetType = {
  email: '',
};
export const MembersRequestResetSchema = z.object({
  email: z.string().email('Valid email address is required'),
});

/**
 *[page 2B] users-reset-password
 */
export type Members2FAType = {
  verification_code: string;
  email: string;
};
export const Members2FADefault: Members2FAType = {
  verification_code: '',
  email: '',
};
export const Members2FASchema = z.object({
  verification_code: z.string().min(6, 'Code must be at least 6 characters long'),
  email: z.string().email('Valid email address is required'),
});

/**
 *[page 2C] users-reset-password
 */
export type ResetPasswordUpdateType = {
  email: string;
  code: string;
  new_password: string;
};
export type MembersResetPasswordType = {
  new_password: string;
  confirm_password: string;
};
export const MembersResetPasswordDefault: MembersResetPasswordType = {
  new_password: '',
  confirm_password: '',
};
export const MembersResetPasswordSchema = z
  .object({
    new_password: z.string().min(6, 'Password must be at least 6 characters long'),
    confirm_password: z.string().min(6, 'Confirm Password must be at least 6 characters long'),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: 'Passwords & confirm password must match',
    path: ['confirm_password'],
  });
