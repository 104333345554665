import AppLayout from '../../layouts/AppLayout';
import { Switch } from "../../components/switch";
import { useState, useEffect } from "react";
import { Setting } from '../../app-schemas/community/community.interface';
import { useSettings } from '../../api-services/settings/use-setting';
import { useUpdateSettings } from '../../api-services/settings/use-update-settings';
import { pageLoader } from '../../app-utils';

const Settings = () => {
  document.body.classList.remove('overflow-hidden');

  const [settings, setSettings] = useState<Setting>({
    account_name: false,
    phone_details: false,
    live_notification: false,
    group_messages: false,
    preferred_lang: "English (Device Language)",
    autoplay_media: false,
    autodownload_media: false
  });
  const updateSettings = (name: keyof Setting, value: boolean | string) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  // React Query
  const userSettings = useSettings()
  const updateUserSettings = useUpdateSettings()

  // Submit Update
  const onSubmit = async (data: any) => {
    updateUserSettings.mutate(data);
  };

  useEffect(() => {
    if (updateUserSettings.isSuccess) {
      pageLoader()
    }
  }, [updateUserSettings.isSuccess]);

  useEffect(() => {
    // If data fetch is successful
    if (userSettings.isSuccess) {
      Object.keys(userSettings.data.data).forEach(key => {
        const settingKey = key as keyof Setting;
        updateSettings(settingKey, userSettings.data.data[settingKey]);
      });
    }
    // eslint-disable-next-line
  }, [userSettings.isSuccess]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked; // Explicitly cast to HTMLInputElement

    updateSettings(name as keyof Setting, type === 'checkbox' ? checked : value);
  };

  const handleSwitchChange = (name: keyof Setting, checked: boolean) => {
    updateSettings(name, checked);
  };

  const updateSetting = () => {
    pageLoader(true)
    onSubmit(settings)
  };

  return (
    <AppLayout title="Catapot - Settings">
      
      <section className="bg-white w-full mb-10 md:mt-0 md:mb-0">
      <div className="min-h-screen py-5 px-8 rounded-md flex flex-col gap-4 w-full lg:gap-3 lg:w-[55%] mx-auto box-border">
        <h1 className="text-xl font-bold">Privacy and Settings</h1>
        <div className="min-h-screen py-5  rounded-md bg-white flex flex-col gap-2 lg:gap-3 w-full  lg:w-full mx-auto box-border">
          <h1 className="text-lg">Account Preference</h1>
          <hr />
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-sm">Account Name</h2>
            <div className="flex justify-between">
              <p className="text-[#C2B9B9] text-xs">{settings?.account_name ? 'Visible' : 'Not visible'}</p>
              <Switch
              checked={settings.account_name}
              onCheckedChange={(checked:any) => handleSwitchChange('account_name', checked)}
            />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-sm">Phone Details</h2>
            <div className="flex justify-between">
              <p className="text-[#C2B9B9] text-xs">{settings?.phone_details ? 'Visible' : 'Not visible'}</p>
              <Switch checked={settings.phone_details}
              onCheckedChange={(checked:any) => handleSwitchChange('phone_details', checked)}/>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-sm">Live Notification Tab</h2>
            <div className="flex justify-between">
              <p className="text-[#C2B9B9] text-xs">{settings.live_notification ? 'Visible' : 'Not visible'}</p>
              <Switch checked={settings.live_notification}
              onCheckedChange={(checked:any) => handleSwitchChange('live_notification', checked)} />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-sm">Group Messages</h2>
            <div className="flex justify-between">
              <p className="text-[#C2B9B9] text-xs">{settings?.group_messages ? 'Visible' : 'Not visible'}</p>
              <Switch checked={settings.group_messages}
              onCheckedChange={(checked:any) => handleSwitchChange('group_messages', checked)} />
            </div>
          </div>
          <h1 className="text-lg">General Preference</h1>
          <hr />
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-sm">Preferred Language</h2>
            <div className="flex justify-between">
              <p className="text-[#C2B9B9] text-xs">
                {settings?.preferred_lang}
              </p>
              <select name="preferred_lang" className="text-xs" value={settings.preferred_lang} onChange={handleChange}>
              <option selected className="text-xs" value="English (Device Language)"> English (Device Language)</option>
              <option value="Spanish" className="text-xs">Spanish</option>
              <option value="French" className="text-xs">French</option>
              {/* Add more options as needed */}
            </select>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-sm">Auto-play audio</h2>
            <div className="flex justify-between">
              <p className="text-[#C2B9B9] text-xs">{settings?.autoplay_media ? 'On' : 'Off'}</p>
              <Switch checked={settings?.autoplay_media}
              onCheckedChange={(checked:any) => handleSwitchChange('autoplay_media', checked)}/>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-sm">Auto Download media</h2>
            <div className="flex justify-between">
              <p className="text-[#C2B9B9] text-xs">{settings?.autodownload_media ? 'On' : 'Off'}</p>
              <Switch checked={settings?.autodownload_media}
              onCheckedChange={(checked:any) => handleSwitchChange('autodownload_media', checked)} />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-between gap-4 pb-4 mb-3 mt-5">
            {/* <button className="h-[51px] justify-center w-full lg:w-[162px] rounded-lg bg-white border border-custom-600 font-bold text-custom-600 text-xs">
              Cancel
            </button> */}
            <button onClick={() => updateSetting()} className="h-[51px] justify-center w-full lg:w-[162px] rounded-lg bg-custom-600 font-bold text-white text-xs">
              Save Preferences
            </button>
          </div>
        </div>
      </div>
    </section>
    </AppLayout>
  );
};

export default Settings;
