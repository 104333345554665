import {useQuery } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { CommunityQueryKeys } from './community.keys';
import { apiRoutes } from '../ApiRoutes';
import { useParams } from 'react-router-dom';

export function useCommunityDetails() {
    const { id } = useParams();
  
    const getCommunityDetailsFn = async () => {
      const response = await apiClient.get(`${apiRoutes.FetchCommunitiesDetails}/${id}`);
      return response.data;
    };
  
    return useQuery({
      queryKey: CommunityQueryKeys.detail(Number(id)),
      queryFn: getCommunityDetailsFn,
      retry: 1,
    });
  }
  