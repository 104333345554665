import React, { useState } from 'react';
import PasswordToggle from './PasswordToggle';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { ErrorMessage } from './ErrorMessage';
import { LuAsterisk } from 'react-icons/lu';

type InputFieldProps = {
  type?: string;
  id: string;
  className?: string;
  placeholder?: string;
  label?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  icon?: React.ComponentType;
  max?: number;
  min?: number;
  step?: number;
  error?: string;
  maxLength?: number;
  disabled?: boolean;
};

const InputField: React.FC<InputFieldProps> = ({
  type = 'text',
  id,
  className = '',
  placeholder,
  label,
  value,
  onChange,
  required = false,
  icon: Icon,
  max,
  min,
  step,
  error,
  maxLength,
  disabled,
}) => {
  const [isFocused, setIsFocused] = useState(true);

  const handlePhoneChange = (value: string) => {
    const event = { target: { value } } as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };

  return (
    <div className={`relative ${className}`}>
      {type === 'tel' ? (
        <>
          <PhoneInput
            country={'ng'}
            value={value as string}
            onChange={handlePhoneChange}
            inputClass={`focus:outline-none focus:shadow-outline border border-input border-gray-300 rounded-md pl-9 px-4 py-3 w-full text-md text-gray-700 focus:shadow-lg focus:border-[#58595c] ${
              error && 'danger'
            } ${className}`}
            inputProps={{
              id,
              // required,
              onFocus: () => setIsFocused(true),
              onBlur: () => setIsFocused(true),
              autoComplete: 'off',
            }}
          />
          {label && isFocused && (
            <label
              htmlFor={id}
              className="absolute top-[-8px] left-3 text-xs text-gray-600 bg-white px-1"
              style={{ zIndex: '9000' }}
            >
              {required && <span className="text-red-600">* </span>}
              {label}
            </label>
          )}

          {error && <ErrorMessage message={error} />}
        </>
      ) : (
        <>
          {label && isFocused && (
            <label
              htmlFor={id}
              className="flex text-xs text-gray-700 font-[600]"
            >
              {required && (
                <span className="text-red-600 top-[2px] relative">
                  <LuAsterisk />
                </span>
              )}
              {label}
            </label>
          )}

          <div className='relative'>
            {Icon && (
              <span className="absolute p-[5px] rounded-[50px] top-[8px] left-[7px] text-xl text-[#98A1B0]">
                <Icon />
              </span>
            )}

            <input
              type={type}
              id={id}
              className={`focus:outline-none focus:shadow-outline border border-input border-gray-400 rounded-[3px] pl-10 px-4 py-3 w-full text-md text-gray-700 focus:shadow-lg focus:border-[#58595c] ${
                error && 'border-red-700'
              } ${className}`}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onBlur={onChange}
              // onFocus={() => setIsFocused(true)}
              // onBlur={() => setIsFocused(true)}
              // required={required}
              max={max}
              min={min}
              step={step}
              autoComplete="off"
              maxLength={maxLength}
              disabled={disabled}
            />

            {type === 'password' && <PasswordToggle targetId={id} />}

            {error && <ErrorMessage message={error} />}
          </div>
        </>
      )}
    </div>
  );
};

export default InputField;
