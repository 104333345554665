import React, { useState, useEffect, useRef } from 'react';
import { LuAsterisk, LuChevronDown } from 'react-icons/lu';
import { ErrorMessage } from './ErrorMessage';

type Option = {
  value: string | number;
  label: string;
};

type SelectDropdownProps = {
  id: string;
  className?: string;
  placeholder?: string;
  value: string | number;
  onChange: (value: string | number) => void;
  required?: boolean;
  options: Option[];
  label?: string;
  icon?: React.ComponentType;
  error?: string;
};

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  id,
  className = '',
  placeholder,
  value,
  onChange,
  required = false,
  options,
  label,
  icon: Icon,
  error,
}) => {
  // const [isFocused, setIsFocused] = useState(false);
  const [filter, setFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => option.label.toLowerCase().includes(filter.toLowerCase())),
    );
  }, [filter, options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
        // setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option: Option) => {
    onChange(option.value);
    setFilter(option.label);
    setIsDropdownOpen(false);
    // setIsFocused(false);
  };

  const handleInputFocus = () => {
    // setIsFocused(true);
    setIsDropdownOpen(true);
  };

  return (
    <div className={`relative ${className}`} ref={selectRef}>
      {label && (
        <label htmlFor={id} className="flex text-xs font-medium text-gray-600 font-[500]">
          {required && (
            <span className="text-red-600 top-[2px] relative">
              <LuAsterisk />
            </span>
          )}
          {label}
        </label>
      )}
      <div
        className={`flex items-center border ${
          error ? 'border-red-700' : 'border-gray-300'
        } ${className}`}
      >
        {Icon && (
          <span className="ml-3 text-[#98A1B0] text-lg">
            <Icon />
          </span>
        )}
        <input
          id={id}
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          onFocus={handleInputFocus}
          onBlur={() => handleOptionClick}
          className={`focus:outline-none px-4 py-3 pl-2 w-full text-md text-gray-700 ${
            Icon ? 'pl-1' : 'pl-1'
          }`}
          placeholder={placeholder}
        />
        <button type="button" className="px-4 text-lg text-gray-500" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <LuChevronDown />
        </button>
      </div>
      {isDropdownOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md max-h-[10rem] overflow-auto">
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              className="px-4 py-2 cursor-pointer hover:bg-gray-200"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
          {filteredOptions.length === 0 && (
            <li className="px-4 py-2 text-gray-500">No options found</li>
          )}
        </ul>
      )}
      {error && <ErrorMessage message={error} />}
    </div>
  );
};

export default SelectDropdown;
