import AppLayout from '../../layouts/AppLayout';
import CommunityCard from '../home/home-components/CommunityCard';
import { useRecommendedCommunities } from '../../api-services/community/useRecommendedCommunities';
import { useCommunities } from '../../api-services/community/use-communities';
import community_api from '../../api-services/community/community.services';
import { Community } from '../../app-schemas/community/community.interface';
import { useNavigate } from 'react-router-dom';
import { SITELINK } from '../../app-routes/Links';
import { CommunityImage } from '../../assets/custom-icons';
import { truncateText } from '../../app-utils';
import { LuPlus } from 'react-icons/lu';

// main-component-ui
const Communities = () => {
  const navigate = useNavigate();
  const recommendedCommunities = useRecommendedCommunities();
  const userCommunities = useCommunities();

  // join a community
  const handleJoinCommunity = async (id: number) => {
    try {
      const res = await community_api.JoinCommunity(id);

      console.log(res);

      if (res?.data?.status) {
        // tinyAlert('success', 'Success');
        recommendedCommunities.refetch();
      }
    } catch (error) {
      // tinyAlert('error', 'An error occured!');
    } finally {
      console.log('dd');
    }
  };

  // switch to a link
  const handleViewDetails = (id: number) => {
    navigate(`${SITELINK.COMMUNITY_ACTIVITIES}/${id}`);
  };

  // switch to a link
  const handleCreateCommunity = () => {
    navigate(SITELINK.NEW_COMMUNITIES);
  };

  return (
    <AppLayout title="Catapot - Community">
      <div className="w-full bg-white">
        <h2 className="z-50 px-2 font-medium text-center text-md bg-white shadow-lg border-b p-3">
          Communities
        </h2>

        <div className="flex gap-4 hidden">
          <p className="mt-1 text-sm font-medium">Communities</p>
        </div>
        <button
          type="button"
          onClick={handleCreateCommunity}
          className="hidden bg-custom-600 text-white font-semibold rounded-full px-3 text-[13px] shadow-lg border"
        >
          Create
        </button>
      </div>
      <section className={`mt-1 border shadow-sm`}>
        {/* community area */}
        <div className="p-3 border pb-5 bg-white w-full flex overflow-x-scroll scroll-smooth scroll-style mt-2 pb-2">
          <button
          onClick={handleCreateCommunity}
            className="bg-[#fff] border-gray-300 border rounded-xl mr-3 flex custom-min-width justify-center items-center p-6"
          >
            <div className="flex flex-col items-center gap-2 text-center">
              <div className="justify-center flex flex-col item-center mt-3">
                <LuPlus className="text-[3rem] bg-custom-600 text-white font-semibold py-2 rounded-full shadow-xl" />
              </div>

              <p className="justify-center text-xs font-semibold mt-2 text-gray-600">Add New</p>
            </div>
          </button>

          {recommendedCommunities.isLoading ? (
            /* UI for lazy loding */
            <div className="recommended-communities-lazy-loding-ui"></div>
          ) : (
            <>
              {!!recommendedCommunities?.data?.length &&
                recommendedCommunities?.data?.map((community: Community, index: number) => (
                  <CommunityCard
                    key={index}
                    community={community}
                    onClick={handleJoinCommunity}
                    title="join"
                    icon={true}
                    className="max-w-[35%]"
                  />
                ))}
            </>
          )}
        </div>

        {/* community area */}
        <div className="flex flex-col w-full mt-2 pb-2 mb-12">
          {userCommunities.isLoading ? (
            /* UI for lazy loding */
            <div className="recommended-communities-lazy-loding-ui"></div>
          ) : (
            <>
              {!!userCommunities?.data?.data?.length &&
                userCommunities?.data?.data?.map((community: Community, index: number) => (
                  <div className=" bg-white px-2 gap-1 p-[1px] justify-between border border-gray-300 items-center flex  mb-2">
                    <div className="flex-grow flex py-2">
                      <div className="overflow-hidden flex justify-center">
                        <img
                          src={
                            !!community.cover_image_url ? community.cover_image_url : CommunityImage
                          }
                          alt="community icon"
                          className="object-cover rounded-full w-[50px] h-[50px] shadow-sm overflow-hidden"
                        />
                      </div>

                      <div className=" pt-3 px-2 w-full">
                        <p className="overflow-hidden text-[10px] flex-col  font-medium m-0 flex gap-1">
                          {truncateText(community.name, 20)}
                        </p>
                        <div className="flex-col ">
                          <p className="text-gray-400 text-[8px]">{`${community.members} member${
                            community.members > 1 ? 's' : ''
                          }`}</p>
                        </div>
                      </div>
                    </div>

                    <div className="w-auto flex justify-center px-2 ">
                      <button
                        onClick={() => handleViewDetails(community.id)}
                        className="justify-center px-5 py-1 rounded-full w-auto h-auto shadow-sm flex font-semibold text-gray-200 bg-custom-600 text-xs gap-1"
                      >
                        {/* {icon && <LuPlus className="text-sm" />} */}
                        <span className="capitalize text-xs">View</span>
                      </button>
                    </div>
                  </div>
                  // <CommunityCard key={index} community={community} onClick={handleViewDetails} title='view details' icon={false} className='w-auto mb-2' />
                ))}
            </>
          )}
        </div>
      </section>
    </AppLayout>
  );
};

export default Communities;
