import { useQuery } from '@tanstack/react-query';
import { FeedsQueryKeys } from './feeds.keys';
import apiClient from '../ApiClient';

const all_feeds = async (): Promise<any> => {
    const res = await apiClient.get('posts');
    return res.data?.data;
};

export const useFetchFeeds = () => {
  return useQuery({
    queryKey: FeedsQueryKeys.all,
    queryFn: () => all_feeds(),
  });
};
