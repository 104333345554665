import { useQuery } from '@tanstack/react-query';
import { CommunityQueryKeys } from './community.keys';
import community_api from './community.services';

export const useUserCommunities = (params: any = {}) => {
  return useQuery({
    queryKey: CommunityQueryKeys.all,
    queryFn: () => community_api.MyCommunities(params),
  });
};
