import { Link } from 'react-router-dom';
import { LuArrowLeft } from 'react-icons/lu';
import { SITELINK } from '../../app-routes/Links';

const MySubscription = () => {
  document.body.classList.remove('overflow-hidden');
  return (
    <section className="bg-[#E1F0F8F7] h-[100vh]">
      {/* top lead area and post btn */}
      <div className="fixed top-0 w-full z-50 shadow-lg">
        <div className="flex gap-4 relative px-2 py-3 bg-custom-600 text-white">
          <Link to={SITELINK.HOME} className="font-semibold text-sm flex">
            <LuArrowLeft className="text-2xl mt-[2px]" />
          </Link>

          <p className="text-lg font-medium">My Subscription</p>
        </div>
      </div>

      <div className="mt-[40px] mb-10 md:mt-0 md:mb-0">
        <div className="relative py-3">
          <div className="text-center">
            <p className="text-xs flex flex-col mt-24">No subscription available</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MySubscription;
