import { useQuery } from '@tanstack/react-query';
import { NotificationQueryKeys } from './notifications.keys';
import apiClient from '../ApiClient';

const getUnreadNotifications = async (): Promise<any> => {
    const res = await apiClient.get('notifications/unread');
    return res.data?.data;
};

export const useFetchUnreadNotifications = () => {
  return useQuery({
    queryKey: NotificationQueryKeys.unread,
    queryFn: () => getUnreadNotifications(),
  });
};
