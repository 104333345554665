import { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonComponent from '../../components/ButtonComponent';
import InputField from '../../components/InputField';
import usePreventDefault from '../../custom-hooks/PreventDefault';
import useFormInput from '../../custom-hooks/useFormInput';
import AuthLayout from '../../layouts/AuthLayout';
import { LuArrowLeft, LuFingerprint, LuMail } from 'react-icons/lu';
import {
  MembersRequestResetDefault,
  MembersRequestResetSchema,
  MembersRequestResetType,
  MembersResetPasswordDefault,
  MembersResetPasswordSchema,
  MembersResetPasswordType,
} from '../../app-schemas/authentication.schema';
import { SITELINK } from '../../app-routes/Links';
import { z } from 'zod';
import auth from '../../api-services/auth/authentication.services';
import { FEED_MSSG, RES_MSSG } from '../../api-services/ConstMssg';
import OTPInput from 'react-otp-input';

const RequestResetPassword = () => {
  const navigate = useNavigate();
  const __PDef = usePreventDefault();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [otp, setOtp] = useState('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');

  // handle otp input
  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  };

  // custom hook to get && validate users input page 1
  const [formData, handleChange, errors, setValidationErrors] = useFormInput(
    MembersRequestResetDefault,
    MembersRequestResetSchema,
  );

  const [formDataPassword, handleChangePassword, errorsPassword, setValidationErrorsPassword] =
    useFormInput(MembersResetPasswordDefault, MembersResetPasswordSchema);

  useEffect(() => {
    if (otp.length === 6) {
      verify2FACode(otp, formData.email, handleVerifySuccess, handleError);
    }
  }, [otp, formData.email]);

  // -- [1] EMAIL RESET
  const handlePasswordReset = async (e: FormEvent) => {
    __PDef(e);
    setErrorMsg('');
    setSuccessMsg('');

    const { success, data, error } = MembersRequestResetSchema.safeParse(formData);

    if (success) {
      await RequestReset(data);
    } else {
      handleValidationErrors(error);
    }
  };
  const RequestReset = async (data: MembersRequestResetType) => {
    setLoading(true);

    try {
      const res = await auth.RequestReset(data);

      if (res?.data?.message === RES_MSSG.SUCCESS) {
        handleSuccess(res.data);
      } else {
        handleError(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };
  const handleSuccess = (res: any) => {
    setSuccessMsg(FEED_MSSG.CODE_SENT + ' ' + formData.email);
    setPage(2);
  };
  const handleError = (message: string) => {
    setErrorMsg(message);
  };
  const handleValidationErrors = (error: z.ZodError<MembersRequestResetType>) => {
    const newErrors: Partial<Record<keyof MembersRequestResetType, string>> = {};
    error.errors.forEach((err) => {
      newErrors[err.path[0] as keyof MembersRequestResetType] = err.message;
    });

    setValidationErrors(newErrors);
  };

  // -- [2] VERIFY EMAIL
  const handleVerify2FA = async (e: FormEvent) => {
    __PDef(e);
    
    setErrorMsg('');
    setSuccessMsg('');

    if (otp.length === 6) {
      await verify2FACode(otp, formData.email, handleVerifySuccess, handleError);
    } else {
      setSuccessMsg(FEED_MSSG.OTP_LENGTH_SHORT);
    }
  };
  const verify2FACode = async (
    otp: string,
    email: string,
    handleVerifySuccess: any,
    handleError: any,
  ) => {
    setLoading(true);

    const data = {
      verification_code: otp,
      email: email,
    };

    try {
      const res = await auth.VerifyCode(data);

      if (res?.data?.message === RES_MSSG.VERIFIED) {
        handleVerifySuccess(res.data);
      } else {
        handleError(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };
  const handleVerifySuccess = (res: any) => {
    setSuccessMsg(FEED_MSSG.VERIFIED);
    setPage(3);
  };

  // -- [3] UPDATE PASSWORD
  const handlePasswordUpdate = async (e: FormEvent) => {
    __PDef(e);
    setErrorMsg('');
    setSuccessMsg('');
    const { success, data, error } = MembersResetPasswordSchema.safeParse(formDataPassword);

    if (success) {
      await requestUpdate(data);
    } else {
      handleUpdateValidationErrors(error);
    }
  };
  const requestUpdate = async (data: MembersResetPasswordType) => {
    setLoading(true);
    const formdata = {
      email: formData.email,
      code: otp,
      new_password: data.new_password,
    };

    try {
      const res = await auth.UpdatePassword(formdata);

      if (res?.data?.message === RES_MSSG.SUCCESS) {
        handleUpdateSuccess(res.data);
      } else {
        handleError(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateSuccess = (res: any) => {
    setSuccessMsg(FEED_MSSG.RESET_OK);

    navigate(SITELINK.LOGIN);
  };
  const handleUpdateValidationErrors = (error: z.ZodError<MembersResetPasswordType>) => {
    const newErrors: Partial<Record<keyof MembersResetPasswordType, string>> = {};
    error.errors.forEach((err) => {
      newErrors[err.path[0] as keyof MembersResetPasswordType] = err.message;
    });

    setValidationErrorsPassword(newErrors);
  };

  return (
    <AuthLayout title="Reset password">
      {/* enter email section */}
      {page === 1 && (
        <section>
          {/* Goback links */}
          <div className="mt-10">
            <Link
              to={'/'}
              className="flex gap-1 text-start text-sm font-medium mb-10 mt-6 font-medium text-custom-600"
            >
              <LuArrowLeft className="text-2xl" />
              <span className="font-semibold mt-[2px]">Go back</span>
            </Link>
          </div>

          {/* hero-text area */}
          <div className="flex flex-col items-start mt-12">
            <h4 className="text-[1.2rem] font-[600] text-gray-900">Password Reset</h4>
            <p className="text-xs font-[500] text-gray-600 pr-24">
              Enter your email address to reset password
            </p>

            <p className="mt-12 font-semibold">Step 1 of 3</p>
          </div>


          <div className="text-red-600 text-[10px] col-span-full text-center">{errorMsg}</div>
          <div className="text-green-600 text-[10px] col-span-full text-center">{successMsg}</div>

          {/* complete form area */}
          <form onSubmit={handlePasswordReset} className="grid grid-cols-2 gap-4 mt-8">
            {/* enter email */}
            <InputField
              className="col-span-full text-sm rounded-lg"
              type="email"
              id="email"
              placeholder="Enter email address"
              label="Enter your registered email address"
              value={formData.email}
              onChange={handleChange}
              required
              icon={LuMail}
              error={errors?.email}
            />

            {/* action btn */}
            <ButtonComponent
              type="submit"
              className="text-sm py-3 rounded-lg text-white bg-custom-600"
              disabled={loading}
              loading={loading}
            >
              Continue
            </ButtonComponent>
          </form>
        </section>
      )}

      {/* enter reset code section */}
      {page === 2 && (
        <section>
          {/* hero-text area */}
          <div className="flex flex-col items-start mt-12">
            <h4 className="text-[1.4rem] font-[600] text-gray-900">Enter Reset Code</h4>
            <p className="text-sm font-[500] text-gray-600">
              {' '}
              Enter the code sent to your email address {formData.email}
            </p>

            <p className="mt-12 font-semibold">Step 2 of 3</p>
          </div>


          <div className="text-red-600 text-[10px] col-span-full text-center">{errorMsg}</div>
          <div className="text-green-600 text-[10px] col-span-full text-center">{successMsg}</div>

          {/* complete form area */}
          <form onSubmit={handleVerify2FA} className="mt-8 w-full">
            <div className="flex justify-start items-start mb-5">
              <OTPInput
                value={otp}
                onChange={handleOTPChange}
                numInputs={6}
                shouldAutoFocus
                inputStyle={{
                  width: '2.2rem',
                  height: '2.2rem',
                  margin: '0 0.5rem 0 0rem',
                  fontSize: '1.2rem',
                  borderRadius: 5,
                  border: '1px solid #6d7986',
                }}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            {/* action btn */}
            <div className="mb-5">
              <ButtonComponent
                type="submit"
                className="max-w-[15.5rem] text-sm  py-3 rounded-lg text-white bg-custom-600"
                disabled={loading}
                loading={loading}
              >
                Verify Code
              </ButtonComponent>
            </div>
          </form>

          {/* extra reset links */}
          <div>
            <div className="text-start mb-10 text-sm font-medium text-gray-600">
              Did not get the code?{' '}
              <button onClick={() => RequestReset({ email: formData.email })}>
                <u className="text-blue-500 text-semibold">Resend code</u>
              </button>
            </div>
          </div>
        </section>
      )}

      {/* enter preferred password section */}
      {page === 3 && (
        <section>
          {/* hero-text area */}
          <div className="flex flex-col items-start mt-12">
            <h4 className="text-[1.4rem] font-[600] text-gray-900">Update your Password</h4>
            <p className="text-sm font-[500] text-gray-600">Enter your preferred password</p>

            <p className="mt-12 font-semibold">Step 3 of 3</p>
          </div>


          <div className="text-red-600 text-[10px] col-span-full text-center">{errorMsg}</div>
          <div className="text-green-600 text-[10px] col-span-full text-center">{successMsg}</div>

          {/* complete form area */}
          <form onSubmit={handlePasswordUpdate} className="grid grid-cols-2 gap-4 mt-8">
            
            {/* enter password */}
            <InputField
              className="col-span-full text-sm rounded-lg"
              type="password"
              id="new_password"
              placeholder="* * * * * * * *"
              label="Password"
              value={formDataPassword.new_password}
              onChange={handleChangePassword}
              required
              icon={LuFingerprint}
              error={errorsPassword?.new_password}
            />

            {/* enter password */}
            <div className="col-span-full">
              <InputField
                className="col-span-full  text-sm rounded-lg"
                type="password"
                id="confirm_password"
                placeholder="* * * * * * * *"
                label="Re-enter Password"
                value={formDataPassword.confirm_password}
                onChange={handleChangePassword}
                required
                icon={LuFingerprint}
                error={errorsPassword?.confirm_password}
              />
            </div>

            {/* action btn */}
            <ButtonComponent
              type="submit"
              className="text-sm py-3 rounded-lg text-white bg-custom-600"
              disabled={loading}
              loading={loading}
            >
              Update Password
            </ButtonComponent>
          </form>
        </section>
      )}
    </AuthLayout>
  );
};

export default RequestResetPassword;
