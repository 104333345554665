import { Link } from 'react-router-dom';
import { LuArrowLeft } from 'react-icons/lu';
import { Card } from '../../components/card';
import { CheckedIcon } from '../../assets/custom-icons';
import ButtonComponent from '../../components/ButtonComponent';
import { SITELINK } from '../../app-routes/Links';

const Subscription = () => {
  document.body.classList.remove('overflow-hidden');
  return (
    <section className="bg-[#E1F0F8F7] h-[100vh]">
      {/* top lead area and post btn */}
      <div className="fixed top-0 w-full z-50 shadow-lg">
        <div className="flex gap-4 relative px-2 py-3 bg-custom-600 text-white">
          <Link to={SITELINK.HOME} className="font-semibold text-sm flex">
            <LuArrowLeft className="text-2xl mt-[2px]" />
          </Link>

          <p className="text-lg font-medium">Premium Services</p>
        </div>
      </div>

      <section className="bg-[#fff] mt-[40px] mb-10 md:mt-0 md:mb-0">
        <div className="min-h-screen py-6 px-3 rounded-md bg-white flex mx-auto box-border">
          <div className="flex flex-col">
            <h1 className="text-lg font-medium mb-0">Select a package</h1>
            <p className="text-[#000] text-xs">
              You are currently on free version. You can upgrade to other available plans.
            </p>

            <div className="grid w-full lg:w-[90%] mx-auto grid-col-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
              <Card className="rounded-lg h-[434px] flex flex-col justify-between">
                <div className="bg-[#003049] rounded-t-lg flex h-[110px] flex-col items-center text-white justify-center">
                  <p className="text-lg">Free</p>
                  <h1 className="text-3xl font-bold">$0 / Month</h1>
                </div>
                <div className="flex flex-col gap-8 ml-10">
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">5 Sponsored post</p>
                  </div>
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">2 Boost per month</p>
                  </div>
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">1 Premium post</p>
                  </div>
                </div>
                <ButtonComponent
                  className="w-[200px] mt-2 text-sm  py-3 rounded-b-lg text-white bg-[#003049]"
                  type="submit"
                >
                  Current Plan
                </ButtonComponent>
              </Card>
              <Card className="rounded-lg h-[434px] flex flex-col justify-between">
                <div className="bg-[#38ACA8] rounded-t-lg flex h-[110px] flex-col items-center text-white justify-center">
                  <p className="text-lg">Premium</p>
                  <h1 className="text-3xl font-bold">$9 / Month</h1>
                </div>
                <div className="flex flex-col gap-8 ml-10">
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">5 Sponsored post</p>
                  </div>
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">8 Boost per month</p>
                  </div>
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">15 Premium post</p>
                  </div>
                </div>
                <ButtonComponent
                  className="w-[200px] mt-2 text-sm  py-3 rounded-b-lg text-white bg-[#38ACA8]"
                  type="submit"
                >
                  Upgrade to premium
                </ButtonComponent>
              </Card>
              <Card className="rounded-lg h-[434px] flex flex-col justify-between">
                <div className="bg-[#FFAC06] rounded-t-lg flex h-[110px] flex-col items-center text-white justify-center">
                  <p className="text-lg">Ultimate</p>
                  <h1 className="text-3xl font-bold">$15 / Month</h1>
                </div>
                <div className="flex flex-col gap-8 ml-10">
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">10 Sponsored post</p>
                  </div>
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">25 Boost per month</p>
                  </div>
                  <div className="flex gap-2">
                    <img src={CheckedIcon} alt="" />
                    <p className="text-[#003049]">Unlimited Premium post</p>
                  </div>
                </div>
                <ButtonComponent
                  className="w-[200px] mt-2 text-sm  py-3 rounded-b-lg text-white bg-[#FFAC06]"
                  type="submit"
                >
                  Upgrade to Ultimate
                </ButtonComponent>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
export default Subscription;
