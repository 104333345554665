import {useQuery } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { SettingsQueryKeys } from './setting-query-key';
import { apiRoutes } from '../ApiRoutes';

const getSettingsFn = async () => {
  const response = await apiClient.get(apiRoutes.FetchSetting);
  return response.data;
};

export function useSettings() {
  return useQuery({
    queryKey: SettingsQueryKeys.all,
    queryFn: getSettingsFn,
  });
}