import { makeRequestWithToken } from '../services';

function appendArrayToFormData(formData: FormData, key: string, array: number[] | string[]) {
  array.forEach((value) => formData.append(key, value.toString()));
}

class Feeds {
  // list of all relevant post
  async all_feeds(data: any = {}): Promise<any> {
    try {
      const params = {};

      const res = await makeRequestWithToken.get('posts', params);
      return res.data?.data;
    } catch (error) {
      return error;
    }
  }

  async CreateNewFeed(data: any): Promise<any> {
    try {
      const formdata = new FormData();

      if (!!data.images) formdata.append('image_files[]', data.images[0]);

      formdata.append('content', data.text_content);

      if (!!data.audio) formdata.append('audio_files[]', data.audio);

      const selected_community_ids = data.selected_community;

      // const selected_community_ids: number[] = data.selected_community.map(
      //   (option: any) => option.value,
      // );

      appendArrayToFormData(formdata, 'community_ids[]', selected_community_ids);

      return await makeRequestWithToken.post('post/create', formdata);
    } catch (error) {
      return error;
    }
  }
}

const feeds_api = new Feeds();
export default feeds_api;
