import {
  Members2FAType,
  MembersLoginType,
  MembersRequestResetType,
  ResetPasswordUpdateType,
} from '../../app-schemas/authentication.schema';
import { GOOGLE_REDIRECT_URI } from '../process';
import { makeRequestWithoutToken } from '../services';

class UsersAuth {
  // --- LOGIN SERVICES
  // 1 login via email
  async EmailLogin(data: MembersLoginType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append('email', data.email);
      formdata.append('password', data.password);

      return await makeRequestWithoutToken.post('login/email', formdata);
    } catch (error) {
      return error;
    }
  }

  // 2 login via Google
  async GooglelLogin(token: string): Promise<any> {
    try {
      let formdata = new FormData();
      let redirect_uri = GOOGLE_REDIRECT_URI || '';
      formdata.append('code', token);
      formdata.append('redirect_uri', redirect_uri);
      formdata.append('cookie', token);

      const response = await makeRequestWithoutToken.post('login/google', formdata);

      return response;
    } catch (error) {
      return error;
    }
  }

  // 3  login via Facebook
  async FacebooklLogin(data: MembersLoginType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append('access_token', data.email);

      const response = await makeRequestWithoutToken.post('login/facebook', formdata);

      return response;
    } catch (error) {
      return error;
    }
  }

  // 4  logout from account
  async Logout(data: MembersLoginType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append('access_token', data.email);

      // logout withTOken
      const response = await makeRequestWithoutToken.post('logout', formdata);

      return response;
    } catch (error) {
      return error;
    }
  }

  // 5 users-request-reset
  async RequestReset(data: MembersRequestResetType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append('email', data.email);

      const response = await makeRequestWithoutToken.post('password/reset', formdata);

      return response;
    } catch (error) {
      return false;
    }
  }

  // 6 users-reset-password
  async UpdatePassword(data: ResetPasswordUpdateType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append('email', data.email);
      formdata.append('code', data.code);
      formdata.append('new_password', data.new_password);

      const response = await makeRequestWithoutToken.post('password/update', formdata);

      return response;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  // 7 user_login_with_code
  async VerifyCode(data: Members2FAType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append('code', data.verification_code);
      formdata.append('email', data.email);

      const response = await makeRequestWithoutToken.post('verify/email', formdata);

      return response;
    } catch (error) {
      return error;
    }
  }
}

const auth = new UsersAuth();
export default auth;
