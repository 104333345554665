// read-more article on react-query optimistic-updates
// https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SearchQueryKeys } from './search.keys';
import apiClient from '../ApiClient';

const searchCommunities = async (search_param: string): Promise<any> => {
  let params = '?search_param=' + search_param;

  const res = await apiClient.get('community' + params);
  return res.data;
};

export const useSearchCommunities = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: searchCommunities,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: SearchQueryKeys.all });
      const previousData: any = queryClient.getQueryData(SearchQueryKeys.all);
      queryClient.setQueryData(SearchQueryKeys.all, []);
      return { previousData };
    },
    onSuccess: async (data) => {
      console.log(data);
      queryClient.setQueryData(SearchQueryKeys.all, data);
    },
    onError: (err, newUser, context?: any) => {
      // Rollback to the previous data on error
      if (context?.previousData) {
        queryClient.setQueryData(SearchQueryKeys.all, context.previousData);
      }
    },
  });
};
