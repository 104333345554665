import {useQuery } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { MessageQueryKeys } from './message-query-key';
import { apiRoutes } from '../ApiRoutes';

const getConversationFn = async () => {
  const response = await apiClient.get(apiRoutes.FetchConversations);
  return response.data;
};

export function useConversations() {
  return useQuery({
    queryKey: MessageQueryKeys.all,
    queryFn: getConversationFn,
  });
}