import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { SettingsQueryKeys } from './setting-query-key';
import { apiRoutes } from '../ApiRoutes';

const createUpdateFn = async (newSetting: {}) => {
  const response = await apiClient.put(apiRoutes.UpdateSetting, newSetting);
  return response.data;
};

// https://tanstack.com/query/latest/docs/react/guides/optimistic-updates
export function useUpdateSettings() {
  const queryClient = useQueryClient();
//   const { toast } = useToast();

  return useMutation({
    mutationFn: createUpdateFn,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: SettingsQueryKeys.all });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: SettingsQueryKeys.all });
    },
    onError: (err, newUser, context?: any) => {
      // tinyAlert('error', err.message);
      queryClient.setQueryData(SettingsQueryKeys.all, context.previousUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: SettingsQueryKeys.all });
    },
  });
}
