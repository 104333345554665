import { useEffect } from 'react';
import { LuArrowLeft, LuChevronRight } from 'react-icons/lu';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { setUnreadNotificationsAsRead } from '../../api-services/notifications/useClearUnreadNotifications';
import { useFetchNotifications } from '../../api-services/notifications/useFetchNotifications';
import { SITELINK } from '../../app-routes/Links';
import { generateRandomHexColor, getFirstLetters } from '../../app-utils';
import { Loader, Verify } from '../../assets/custom-icons';

// main-component-ui
const Notifications = () => {
  document.body.classList.remove('overflow-hidden');
  const notification_data = useFetchNotifications();

  useEffect(() => {
    setUnreadNotificationsAsRead();
  }, []);

  return (
    <section className="bg-[#E1F0F8F7] h-[100vh]">
      {/* top lead area and post btn */}
      <div className="fixed top-0 w-full z-50 shadow-lg">
        <div className="flex gap-4 relative px-2 py-3 bg-custom-600 text-white">
          <Link to={SITELINK.HOME} className="font-semibold text-sm flex">
            <LuArrowLeft className="text-2xl mt-[2px]" />
          </Link>

          <p className="text-lg font-medium">Notifications</p>
        </div>
      </div>

      {/* content area */}
      <div className="mt-[48px] bg-[#E1F0F8F7] p-3">
        {notification_data.isLoading ? (
          <div className="h-[90vh] flex justify-center items-center">
            <img src={Loader} alt="pre-loader" className="w-[20px] h-[20px]" />
          </div>
        ) : (
          <div>
            {notification_data?.data?.length > 0 ? (
              notification_data?.data?.map((notification: any, index: number) => (
                <div className="p-3 border shadow-sm bg-white mb-2 rounded-xl" key={index}>
                  {/* <h4 className="font-medium text-sm mb-2">Post Reaction</h4> */}
                  <div className="flex gap-2">
                    <Link
                      to={`${SITELINK.MY_PROFILE}/${notification?.creator?.username}`}
                      className="bg-gray-200 w-[20px] h-[20px] rounded-full"
                    >
                      {notification?.creator?.profile_picture_url ? (
                        <LazyLoad height={200} offset={1000} once key={index}>
                          <img
                            alt="profile"
                            src={notification?.creator?.profile_picture_url}
                            className="object-cover rounded-full w-[20px] h-[20px] bg-gray-200 shadow-sm overflow-hidden"
                          />
                        </LazyLoad>
                      ) : (
                        <span
                          className={`block w-[20px] h-[20px] rounded-full border border-green-100`}
                          style={{ background: generateRandomHexColor() }}
                        >
                          <h3 className="text-white uppercase text-[10px] font-[500] relative top-[1px] left-[5px]">
                            {getFirstLetters(notification?.creator?.username)}
                          </h3>
                        </span>
                      )}
                    </Link>

                    <div>
                      <p className="text-sm text-gray-800 font-semibold flex gap-1">
                        <span>
                          {notification?.creator?.username
                            ? notification?.creator?.username
                            : 'Anonymous'}
                        </span>
                        <span>
                          {notification?.creator?.is_verified && (
                            <img src={Verify} alt="" className="h-[12px]" />
                          )}
                        </span>
                      </p>
                    </div>
                  </div>

                  <p className="text-xs border-b font-medium pb-2 mt-2 text-gray-500">
                    {notification.post_reaction}
                  </p>

                  <div className="flex justify-between pb-1 text-gray-400">
                    <p className="text-[10px] font-medium pt-2">{notification?.created}</p>
                    <Link
                      to={SITELINK.VIEW_POSTS+'/'+notification?.post_id}
                      className="text-[10px] font-medium pt-2 flex gap-1"
                    >
                      <span>View</span>
                      <LuChevronRight className="mt-[2.5px]" />
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div className="relative top-[50px] py-3">
                <div className="text-center">
                  <p className="text-xs flex flex-col mt-24">No notification available</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Notifications;
