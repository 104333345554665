import { makeRequestWithToken } from '../services';

function appendArrayToFormData(formData: FormData, key: string, array: number[] | string[]) {
  array.forEach((value) => formData.append(key, value.toString()));
}

class Comminity {
  // recommended communities
  async Communities(data: any = {}): Promise<any> {
    try {
      const params = {
        community_city: '',
        search_param: '',
      };

      const res = await makeRequestWithToken.get('community', params);

      return res?.data?.data;
    } catch (error) {
      return error;
    }
  }

  // user joined communites
  async MyCommunities(data: any): Promise<any> {
    try {
      const params = {
        community_country_id: 4,
        community_city: '{{$randomCity}}',
      };
      return await makeRequestWithToken.get('community/active', params); // list of countries
    } catch (error) {
      return error;
    }
  }

  async CommunityDetails(data: any): Promise<any> {
    try {
      const params = {
        community_id: 2,
      };
      return await makeRequestWithToken.get('community/details', params); // list of countries
    } catch (error) {
      return error;
    }
  }

  async CommunityMembers(data: any): Promise<any> {
    try {
      const params = {
        community_id: 2,
      };
      return await makeRequestWithToken.get('community/members', params); // list of countries
    } catch (error) {
      return error;
    }
  }

  async CommunityActivities(data: any): Promise<any> {
    try {
      const params = {
        community_id: 2,
      };
      return await makeRequestWithToken.get('community/activities', params); // list of countries
    } catch (error) {
      return error;
    }
  }

  async CreateCommunity(data: any): Promise<any> {
    try {
      const formdata = new FormData();
      appendArrayToFormData(formdata, 'community_tags[]', data.tags);
      formdata.append('community_banner', data.file);
      formdata.append('community_name', data.communityName);
      formdata.append('community_description', data.communityDescription);

      return await makeRequestWithToken.post('community/create', formdata);
    } catch (error) {
      return error;
    }
  }

  async JoinCommunity(data: any): Promise<any> {
    try {
      const params = {
        community_id: data,
      };
      return await makeRequestWithToken.post('community/join', params); // list of countries
    } catch (error) {
      return error;
    }
  }

  async UpdateCommunity(data: any): Promise<any> {
    try {
      const formdata = new FormData();
      appendArrayToFormData(formdata, 'community_tags[]', data.tags);
      formdata.append('community_banner', data.file);
      formdata.append('community_id', data.communityName);
      formdata.append('community_country_id', data.communityDescription);
      formdata.append('is_public', data.is_public);

      return await makeRequestWithToken.put('community/update', formdata);
    } catch (error) {
      return error;
    }
  }
}

const community_api = new Comminity();
export default community_api;
