import React from 'react';
import { LuPlus } from 'react-icons/lu';
import { Community } from '../../../app-schemas/community/community.interface';
import { CommunityImage } from '../../../assets/custom-icons';
import { truncateText } from '../../../app-utils';
import clsx from "clsx";

interface CommunityCardProps {
  community: Community;
  onClick?: (communityID: number) => void;
  title: string;
  icon: boolean;
  className?: string;
}

const CommunityCard: React.FC<CommunityCardProps> = ({ community, onClick, title, icon, className }) => {
  return (
    <div className={clsx("p-[4px] rounded-xl justify-between border border-gray-300 items-center flex flex-col", 
    className
    )}>
      <div className="flex-grow flex flex-col items-center">
        <div className="overflow-hidden flex justify-center mt-3">
          <img
            src={!!community.cover_image_url ? community.cover_image_url : CommunityImage}
            alt="community icon"
            className="object-cover rounded-full w-[50px] h-[50px] shadow-sm overflow-hidden"
          />
        </div>

        <div className="text-center pt-3 px-2 w-full">
          <p className="overflow-hidden text-[10px] flex-col justify-center font-medium m-0 flex gap-1">
            {truncateText(community.name, 20)}
          </p>
        </div>
      </div>

      <div className="flex-col justify-between items-center mx-3 mb-1">
        <p className="text-gray-400 text-[8px]">{`${community.members} member${
          community.members > 1 ? 's' : ''
        }`}</p>
      </div>

      <div className="w-full flex justify-center mx-2 px-2 pb-2">
        <button
          onClick={() => onClick && onClick(community.id)}
          className="justify-center px-8 py-[6px] rounded-full shadow-xl flex w-full font-semibold text-gray-200 bg-custom-600 text-xs gap-1 capitalize"
        >
          {icon && <LuPlus className="text-sm" />}
          {title}
        </button>
      </div>
    </div>
  );
};

export default CommunityCard;
