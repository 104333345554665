export const FeedsQueryKeys = {
  all: ['feeds'],
  saved: ['saved'],
  liked: ['liked'],



  feeds: ['all_feeds'],
  details: () => [...FeedsQueryKeys.all, 'detail'],
  members: () => [...FeedsQueryKeys.all, 'member'],
  activities: () => [...FeedsQueryKeys.all, 'activity'],
  detail: (id: number) => [...FeedsQueryKeys.details(), id],
  member: (id: number) => [...FeedsQueryKeys.members(), id],
  activity: (id: number) => [...FeedsQueryKeys.activities(), id],
  pagination: (page: number) => [...FeedsQueryKeys.all, 'pagination', page],
  infinite: () => [...FeedsQueryKeys.all, 'infinite'],
};
