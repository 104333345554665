import axios, { AxiosError } from 'axios';
import { getToken } from '../app-utils';
import { SERVER_URL } from './process';

const apiClient = axios.create({
  baseURL: SERVER_URL,
});

// Add request interceptor to include the Bearer token
apiClient.interceptors.request.use(
  (config) => {
    const token = getToken(); // Get the token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Add the token to headers
    }
    return config;
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // Handle errors globally
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error('API error:', error.response.status, error.response.data);
    } else if (error.request) {
      // No response received
      console.error('Network error:', error.message);
    } else {
      // Something else happened
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  },
);

export default apiClient;
