import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient  from '../ApiClient';
import { CommunityQueryKeys } from './community.keys';
import { apiRoutes } from '../ApiRoutes';
import { tinyAlert } from '../../app-utils/index';

const createCommunityFn = async (newCommunity: FormData) => {
  const response = await apiClient.post(apiRoutes.CreateCommunity, newCommunity);
  return response.data;
};

// https://tanstack.com/query/latest/docs/react/guides/optimistic-updates
export function useCreateCommunity() {
  const queryClient = useQueryClient();
//   const { toast } = useToast();

  return useMutation({
    mutationFn: createCommunityFn,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: CommunityQueryKeys.all });
    },
    onSuccess: (data) => {
      tinyAlert('success', `Community ${data.data.community_name} Created`);
      queryClient.invalidateQueries({queryKey: CommunityQueryKeys.all });
    },
    onError: (err, newUser, context?: any) => {
      tinyAlert('error', err.message);
      queryClient.setQueryData(CommunityQueryKeys.all, context.previousUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: CommunityQueryKeys.all });
    },
  });
}

