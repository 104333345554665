import { makeRequestWithToken } from '../services';

class ContentMgt {
  // 1 Countries
  async Countries(): Promise<any> {
    try 
    {
      return await makeRequestWithToken.get('countries'); // list of countries

    } 
    catch (error) 
    {
      return error; // server error

    }
  }


}

const content_mgt = new ContentMgt();
export default content_mgt;
