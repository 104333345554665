import { FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonComponent from '../../components/ButtonComponent';
import InputField from '../../components/InputField';
import usePreventDefault from '../../custom-hooks/PreventDefault';
import useFormInput from '../../custom-hooks/useFormInput';
import AuthLayout from '../../layouts/AuthLayout';
import { LuMail, LuFingerprint } from 'react-icons/lu';
import {
  MembersLoginDefault,
  MembersLoginSchema,
  MembersLoginType,
  MembersLoginResponse,
} from '../../app-schemas/authentication.schema';
import { FacebookIcon, GoogleIcon } from '../../components/Icons';
import { SITELINK } from '../../app-routes/Links';
import { z } from 'zod';
import auth from '../../api-services/auth/authentication.services';
import { encryptDataWithIV, pageLoader, storeToken, storeUser } from '../../app-utils';
import { useGoogleLogin } from '@react-oauth/google';
import { FEED_MSSG, RES_MSSG } from '../../api-services/ConstMssg';

const UserLogin = () => {
  localStorage.clear();
  
  const navigate = useNavigate();
  const __PDef = usePreventDefault();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  // custom hook to get && validate users input
  const [formData, handleChange, errors, setValidationErrors] = useFormInput(
    MembersLoginDefault,
    MembersLoginSchema,
  );

  // -- [1] EMAIL LOGIN
  const handleEmailLogin = async (e: FormEvent) => {
    __PDef(e);
    setErrorMsg('');
    const { success, data, error } = MembersLoginSchema.safeParse(formData);

    if (success) {
      await EmailLogin(data);
    } else {
      handleValidationErrors(error);
    }
  };

  const EmailLogin = async (data: MembersLoginType) => {
    setLoading(true);

    try {
      const res = await auth.EmailLogin(data);

      if (res?.data?.message === RES_MSSG.SUCCESS) {
        handleLoginSuccess(res.data);
      } else {
        handleLoginError(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };

  const handleLoginError = (message: string) => {
    switch (message) {
      case RES_MSSG.EMAIL_NOT_VERIFIED:
        setErrorMsg(FEED_MSSG.EMAIL_NOT_VERIFIED);
        const plainText = JSON.stringify(formData);
        const encryptedText = encryptDataWithIV(plainText);
        localStorage.setItem('forVerify', encryptedText);
        navigate(SITELINK.AUTH_2FA);
        break;

      case RES_MSSG.INCORRECT_CREDENTIALS:
        setErrorMsg(FEED_MSSG.INCORRECT_CREDENTIALS);
        break;

      default:
        setErrorMsg(message);
        break;
    }
  };

  const handleLoginSuccess = (res: MembersLoginResponse) => {
    const responseData = res;
    const userInfo = responseData.user;

    storeToken(responseData.data);
    storeUser(userInfo);

    if (userInfo.is_onboarded) {
      const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
      localStorage.removeItem('loginRedirectedFromUrl');
      navigate(loginRedirectedFromUrl ?? SITELINK.HOME);
    } else {
      navigate(SITELINK.PROFILE_SETUP);
    }
  };

  // -- ------ -- -----
  // -- [2] GOOGLE AUTH
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      GoogleLogin(tokenResponse.access_token);
    },
    onError: () => {
      setErrorMsg('Google login failed');
    },
  });

  const GoogleLogin = async (response: string) => {
    setLoading(true);
    pageLoader(true);

    try {
      const res = await auth.GooglelLogin(response);

      if (res?.data?.message === RES_MSSG.SUCCESS) {
        handleLoginSuccess(res.data);
      } else {
        handleLoginError(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
      pageLoader();
    }
  };

  const handleFacebookLogin = () => {
    // 1198011421448129
    // window.location.href =
    // "https://www.facebook.com/v20.0/dialog/oauth/?client_id=1198011421448129&redirect_uri=" +
    // fbDecodedRedirectURI +
    // "&state={st%3Dstate123abc%2Cds%3D123456789}";
  };

  // -- ------ -- -----
  const handleValidationErrors = (error: z.ZodError<MembersLoginType>) => {
    const newErrors: Partial<Record<keyof MembersLoginType, string>> = {};
    error.errors.forEach((err) => {
      newErrors[err.path[0] as keyof MembersLoginType] = err.message;
    });

    setValidationErrors(newErrors);
  };

  return (
    <AuthLayout title="Catapot - Discover cultures and communities">
      {/* hero-text area */}
      <div className="flex flex-col items-start mt-12">
        <h4 className="text-[1.3rem] font-[600] text-gray-900">Welcome!</h4>
        <p className="text-xs font-[500] text-gray-600">Join communities and discover cultures</p>
      </div>

      {/* social-media login */}
      <div className="grid gap-3 mt-6">
        <button
          onClick={() => handleGoogleLogin()}
          className="w-full text-sm bg-white border-gray-300 flex gap-4 shadow-sm rounded-lg border border-1 px-2 py-2 hover:shadow-xl font-medium"
        >
          <GoogleIcon />
          <span className="w-3/4 mt-1">Continue with Google</span>
        </button>

        <button
          className="w-full text-sm bg-white border-gray-300 flex gap-4 shadow-sm rounded-lg border border-1 px-2 py-2 hover:shadow-xl font-medium"
          onClick={() => handleFacebookLogin()}
        >
          <FacebookIcon />
          <span className="w-3/4 mt-1">Continue with Facebook</span>
        </button>
      </div>

      {/* OR divider */}
      <div className="mt-5 text-center font-semibold text-xs text-gray-500">
        <p className="inline bg-white px-5 relative top-[10px]">OR</p>
        <p className="border-b border-[#ccc]"></p>
      </div>

      {/* complete form area */}
      <form onSubmit={handleEmailLogin} className="grid grid-cols-2 gap-3 mt-6">
        <div className="text-red-600 text-[10px] col-span-full text-center">{errorMsg}</div>

        {/* enter email */}
        <InputField
          className="col-span-full text-sm rounded-lg font-medium"
          type="email"
          id="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          icon={LuMail}
          error={errors?.email}
          label="Email address"
        />

        {/* enter password */}
        <div className="col-span-full">
          <InputField
            className="col-span-full text-sm rounded-lg font-medium"
            type="password"
            id="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            icon={LuFingerprint}
            error={errors?.password}
            label="Password"
          />
          <div className="text-end">
            <Link className="text-xs text-blue-700 font-[500] pr-3" to={SITELINK.REQUEST_RESET}>
              Forgot password?
            </Link>
          </div>
        </div>

        {/* action btn */}
        <ButtonComponent
          type="submit"
          className="mt-4 text-sm py-3 rounded-lg text-white bg-custom-600"
          disabled={loading}
          loading={loading}
        >
          Continue with Email
        </ButtonComponent>
      </form>

      {/* S4 aggrement area with sign-in link */}
      <div>
        <p className="text-[10px] text-center text-gray-500 mb-8 mt-8">
          By clicking "Continue", you agree to our
          <br />
          <Link to="/privacy-policy" className="underline font-medium text-blue-700">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link to="/terms-of-services" className="underline font-medium text-blue-700">
            Terms of Service
          </Link>
        </p>
      </div>
    </AuthLayout>
  );
};

export default UserLogin;
